.bento-gi {
  &__block {
    position: relative;
    overflow: hidden;
    //background: url('../../../../img/bendo/block_bg.webp') 0 0 / 400px 340px repeat;
    background: #120F17;
    padding: 40px 0 0 0;
    border: 1px solid #2E2B35;
    border-radius: 20px;
    .bento__title-h4 {
      padding: 0 0 40px 32px

    }

  }



  &__image {
    position: relative;
    max-width: 610px;
    aspect-ratio: 610 / 243;
    background: url('../../../../img/bendo/gi-01.webp') 0 0 / contain no-repeat;
  }

  &__snake {
    position: absolute;
    top: 25%;
    right: 15%;
    stroke-dasharray: 1000;
    stroke-dashoffset: 1200;
    &-animation {
      animation: painting 2.5s linear infinite;
    }
  }

  @keyframes painting {
    0% {
      stroke-dashoffset: 1000;
    }
    50% {
      stroke-dashoffset: 0;
    }
    100% {
      stroke-dashoffset: 1200;
    }
  }

  @keyframes painting2 {
    to {
      stroke-dashoffset: 0;
    }
  }

  @media screen and (max-width: 1000px) {
    &__block {
      padding: 36px 0 0 0;
      .bento__title-h4 {
        padding: 0 0 48px 32px

      }
    }
    &__image {
      max-width: 424px;
      aspect-ratio: 424 / 242;
      background: url('../../../../img/bendo/gi-02.webp') 0 0 / contain no-repeat;
    }

    &__snake {
      top: 30%;
      right: 19%;
    }


  }


  @media screen and (max-width: 786px) {
    &__block {
      padding: 32px 0 0 0;
      .bento__title-h4 {
        padding: 0 0 36px 24px

      }
    }
    &__image {
      max-width: 310px;
      aspect-ratio: 310 / 264;
      background: url('../../../../img/bendo/gi-03.webp') 0 0 / contain no-repeat;
    }
    &__snake {
      top: 35%;
      right: 10px;
    }
  }

  @media screen and (max-width: 600px) {

    &__snake {
      top: 35%;
      right: 2px;
    }
  }

  @media screen and (max-width: 560px) {
    &__block {

      .bento__title-h4 {
        padding: 0 0 40px 24px

      }
    }
    &__image {
      max-width: 429px;
      aspect-ratio: 429 / 276;
      background: url('../../../../img/bendo/gi-04.webp') 0 0 / contain no-repeat;
    }

    &__snake {
      top: 30%;
      right: 19%;
    }

  }

  @media screen and (max-width: 480px) {
    &__snake {
      top: 30%;

    }
  }

  @media screen and (max-width: 400px) {
    &__snake {
      top: 30%;

    }
  }
  @media screen and (max-width: 360px) {
    &__block {
      padding: 28px 0 0 0;
      .bento__title-h4 {
        padding: 0 0 0 20px

      }
    }
    &__image {

      max-width: 312px;
      aspect-ratio: 312 / 265;
      background: url('../../../../img/bendo/gi-05.webp') 0 0 / contain no-repeat;
    }

    &__snake {
      top: 34%;
      right: 17%;
    }
  }

  @media screen and (max-width: 320px) {
    &__snake {
      top: 34%;
      right: 14%;
    }
  }

}