
.faq {
  &__item {
    //margin-top: 10px;
    border-bottom: 1px solid #19181d;

  }

  &__top {
    display: flex;
    align-items: center;
    gap: 16px;
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;

    transition: linear 0.3s;
    color: #c6c6c6;
    position: relative;
    padding: 16px 0 24px 0;

    cursor: pointer;
  }

  &__wrapper {
    max-width: 1030px;
    margin: 48px auto 0 auto;
    display: flex;
    flex-direction: column;
    gap: 24px;

    a {
      font-weight: 500;
      font-size: 14px;
      line-height: 140%;
      color: #A98AFF !important;
    }
  }


}


.faq__item.open .faq__bottom {
  margin: 0 0 30px 0;

  a {
    text-decoration: underline !important;
  }
}


.faq__item.open .faq__top::before {
  transform: rotate(90deg);
}


@media screen and (max-width: 1000px) {
  .faq__item.open .faq__top {
    padding: 16px 0 30px 0;
    transition: linear 0.1s;
  }
  .faq__wrapper {
    margin: 40px auto 0 22px;
    gap: 20px;
  }
}

@media screen and (max-width: 786px) {
  .faq__top {
    padding: 12px 0 24px 0;

  }
  .faq__bottom {
  }
  .faq__item.open .faq__top {
    padding: 12px 0 16px 0;
    transition: linear 0.1s;
  }
  .faq__wrapper {
    gap: 16px;
    max-width: 424px;
    margin: 32px auto 0 auto;
  }
}


@media screen and (max-width: 480px) {
  .faq__wrapper {
    margin: 24px auto 0 auto;
    padding: 0 24px;
    max-width: 100%;
    gap: 16px;
    //padding-left: 24px;
  }
  .faq__top {
    padding: 8px 0 16px 0;
    &::before {
      //display: none!important;
    }
  }
  .faq__bottom {
    //padding-left: 0!important;
  }
}

@media screen and (max-width: 360px) {
  .faq__wrapper {
    gap: 16px;
    margin: 24px auto 0 auto;
  }
  .faq__top {
    font-size: 12px;
  }
  .faq__bottom {
    font-size: 12px;
  }
}

.faq__top::before {
  content: "";
  position: relative;
  display: block;
  background-image: url("../../img/arrow.svg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 16px;
  height: 16px;
  line-height: 140%;
  transition: transform 0.3s ease-in-out;
}

.faq__bottom {
  font-size: 14px;
  line-height: 140%;
  color: #c6c6c6;
  padding-left: 32px;
  font-weight: 400;

  max-height: 0;
  overflow: hidden;
  transition: all 0.3s ease-in-out;

  ul {
    li {
      list-style: disc;
    }
  }
}