.title {
  &__h2 {
    font-weight: 600;
    font-size: 36px;
    line-height: 140%;
    color: #ffffff;
  }

}

@media screen and (max-width: 1000px) {
  .title {
    &__h2 {
      font-size: 28px;
    }

  }

}

@media screen and (max-width: 786px) {
  .title {
    &__h2 {

    }

  }

}

@media screen and (max-width: 560px) {
  .title {
    &__h2 {
      font-size: 24px;
    }

  }

}

@media screen and (max-width: 360px) {
  .title {
    &__h2 {
      font-size: 18px;
    }

  }

}