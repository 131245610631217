.footer {
    width: 100%;
    
    
    height: fit-content;
    padding: 64px 24px 120px 24px;
    .logo {
      img {
        width: 40px;
        height: 40px;
      }
    }
    &__content {
      max-width: 1030px;
      margin: 0 auto;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      justify-content: space-between;
      align-items: center;
    }
  
    &__nav {
      display: flex;
      gap: 48px;
      margin: 0 auto;
    }

    &__link {
      font-weight: 400;
      font-size: 14px;
      line-height: 100%;
  
      display: flex;
      align-items: center;
  
      color: #ffffff;
  
      &:hover {
        opacity: 0.8;
      }
    }
  
    &__social {
      margin: 0 0 0 auto;
      display: flex;
      gap: 12px;
  
      a {
        cursor: pointer;
  
        &:hover {
          opacity: 0.8;
        }
  
      }
    }
  }

  @media screen and (max-width: 1000px) {
    .footer {
      padding: 32px 24px 72px 24px;
      // max-width: 912px;
  
      &__content {
        margin: 0 auto;
        max-width: 912px;
      }
    }
  }
  
  @media screen and (max-width: 783px) {
    .footer {
      padding: 64px 15px 80px 15px;
  
      &__content {
        grid-template-columns: 1fr;
        place-items: center;
        gap: 48px;
        max-width: 678px;
  
        .logo {
          span {
          }
        }
      }
  
      &__nav {
        display: flex;
      }
  
      &__social {
        margin: 0 auto;
  
  
      }
    }
  }
  
  @media screen and (max-width: 500px) {
    .footer {
      padding: 16px 15px 64px 15px;
  
      &__content {
        gap: 36px;
      }
  
      .logo {
        width: 48px;
        height: 48px;
  
        img {
          width: 100%;
        }
  
        span {
          display: none;
        }
      }
  
      &__nav {
        gap: 32px;
        width: 100%;
        justify-content: center;
      }
  
      &__link {
        font-size: 12px;
      }
  
      &__content {
        max-width: 280px;
      }
    }
  }
  
  @media screen and (max-width: 360px) {
    .footer {
      &__content {
        gap: 36px;
      }
    }
  }
  