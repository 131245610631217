
.auth {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: calc(100vh - 120px);
  //background: url('../../img/auth_bg.png') left 50% bottom -240px / 960px no-repeat fixed, #000000;
  //width: 963px;
  //height: 505px;

  .button-secondary--large {
    width: 220px;
    height: 56px;
  }

  &__content {
    width: 500px;
    position: relative;

    margin: 80px auto;
  }

  &__connected {
    position: relative;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    gap: 100px;

    &::before {
      position: absolute;
      z-index: 100;
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      background: #29282f;
    }
  }

  &__done {
    position: relative;
    z-index: 200;
    width: 56px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #6b45d7;
    border-radius: 100px;
  }

  &__loading {
    position: relative;
    z-index: 200;
    width: 56px;
    height: 56px;
    background-color: #dddddd;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;

    &-dot {
      width: 5px;
      height: 5px;
      border-radius: 100px;
      background: #c6c6c6;

      &-active {
        background: #111111;
      }
    }
  }

  &__ico {
    position: relative;
    z-index: 200;
    width: 80px;
    height: 80px;
    background-color: #dddddd;
    border-radius: 100px;
    //filter: drop-shadow(0px 35px 14px rgba(204, 204, 204, 0.01))
    //  drop-shadow(0px 19px 12px rgba(204, 204, 204, 0.05))
    //  drop-shadow(0px 9px 9px rgba(204, 204, 204, 0.09))
    //  drop-shadow(0px 2px 5px rgba(204, 204, 204, 0.1))
    //  drop-shadow(0px 0px 0px rgba(204, 204, 204, 0.1));
    box-shadow: 0px 35px 14px rgba(204, 204, 204, 0.01),
    0px 19px 12px rgba(204, 204, 204, 0.05),
    0px 9px 9px rgba(204, 204, 204, 0.09),
    0px 2px 5px rgba(204, 204, 204, 0.1),
    0px 0px 0px rgba(204, 204, 204, 0.1);

    img {
      width: 100%;
    }
  }

  &__bg {
    position: absolute;
    left: 0;
    right: 0;
    bottom: -252px;
    margin: 0 auto;
    width: 963px;
    height: 505px;

    border-radius: 100%;
    background: linear-gradient(90deg, #6944D1 0.17%, #FFD76A 99.46%);
    filter: blur(50px);


  }


  &__text {
    position: relative;
    z-index: 100;

    max-width: 480px;
    margin: 56px auto 0 auto;
    text-align: center;

    h1 {
      margin: 0;
      font-style: normal;
      font-weight: 600;
      font-size: 28px;
      line-height: 130%;
      /* or 36px */

      text-align: center;

      /* White FFFFFF */

      color: #ffffff;
    }

    p {
      padding-top: 20px;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 140%;
      /* or 22px */

      text-align: center;

      /* Dark 300 */

      color: #b0b0b0;
    }

    a {
      margin: 40px auto 0 auto;
    }
  }
}

@media screen and (max-width: 1000px) {
  .auth {
    height: calc(100vh - 120px);
    //background: url('../../img/auth_bg.png') left 50% bottom -200px / 800px no-repeat fixed, #110e14;

    &__content {
      // top: 72px
      margin: 72px auto;
      max-width: 400px;
    }

    &__connected {
      margin: 0 auto;
    }

    &__ico {
      width: 56px;
      height: 56px;
    }

    &__loading {
      width: 40px;
      height: 40px;
    }

    &__text {
      h1 {
        font-size: 24px;
      }

      p {
        font-size: 14px;
      }

      a {
        margin: 32px auto 0 auto;
      }
    }

    &__bg {

      width: 808px;
      height: 489px;
    }
  }
}

@media screen and (max-width: 786px) {
  .auth {
    //background: url('../../img/auth-bg-mob.png') left 50% bottom 0 / auto no-repeat fixed, #110e14;
    &__content {
      margin: 48px auto;
    }

    &__bg {

      width: 548px;
      height: 354px;
    }
  }
}

@media screen and (max-width: 480px) {
  .auth {
    height: calc(100vh - 80px);
    //background: url('../../img/auth-bg-mob-480.png') left 0 bottom 0 / auto no-repeat fixed, #110e14;

    &__content {
      margin: 32px auto 72px auto;
    }

    &__bg {
      width: 1086px;
      height: 354px;
      background: linear-gradient(90deg, #6944D1 0.17%, #FFD76A 99.46%);
      border-radius: 1086px;
      left: -260px;
    }
  }
}

@media screen and (max-width: 360px) {
  .auth {
    height: calc(100vh - 72px);
    //background: url('../../img/auth-bg-360.png') left 0 bottom 0 / auto no-repeat fixed, #110e14;


    &__content {
      margin: 60px auto 56px auto;
    }

    &__bg {
      width: 612px;
      height: 257px;
      left: -120px;
      right: 0;
      bottom: -40px;
      border-radius: 612px;
      background: linear-gradient(90deg, #6944D1 0.17%, #FFD76A 99.46%);
      filter: blur(50px);
    }
  }
}
