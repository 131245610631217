.bento-gsm {
  &__block {
    position: relative;
    overflow: hidden;
    //background: url('../../../../img/bendo/block_bg.png') 0 0 / 400px 340px repeat;
    background: #120F17;
    padding: 40px 20px 24px 32px;
    border: 1px solid #2E2B35;
    border-radius: 20px;

  }

  &__structure {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px;


  }

  &__point {
    max-width: 120.98px;
    aspect-ratio: 120.98 / 64;

    border-radius: 12px;
    border: 1px solid rgba(72, 72, 72, 0.50);
    background: url('../../../../img/bendo/gsm-block-bg.png'), 0 0 / contain #302A44;

    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.31), 0px 12px 26px 0px rgba(0, 0, 0, 0.30);
    display: flex;
    justify-content: center;
    align-items: center;
    color: #D6D6D6;

    font-size: 10px;

    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    width: 100%;
    &--wide {
      background: url('../../../../img/bendo/gsm-wide-bg.png'), 0 0 / contain #302A44;
      max-width: 253px;
      width: 100%;
      font-weight: 600;
      margin-bottom: 12px;
      aspect-ratio: 253 / 55;
    }
  }


  @media screen and (max-width: 1000px) {
    &__block {
      padding: 36px 32px 0 32px;
      position: relative;
      overflow: hidden;
    }
    &__wrapper {
      max-width: 365px;
      height: 100%;

      position: relative;
    }
    &__blocks {
      width: calc(100% + 5px);
      left: -3px;
      position: absolute;
      //max-width: 365px;
    }

    &__point {
      max-width: 176px;
      aspect-ratio: 176 / 56;

      &--wide {
        max-width: 100%;
        aspect-ratio: 365 / 55;
      }
    }
  }


  @media screen and (max-width: 786px) {
    &__block {
      padding: 32px 24px 20px 24px;
    }
    &__wrapper {

    }
    &__blocks {

    }

    &__point {
      max-width: 127.648px;
      aspect-ratio: 127.648 / 50;

      &--wide {
        max-width: 265px;

        aspect-ratio: 265 / 50;
      }
    }
  }

  @media screen and (max-width: 560px) {
    &__block {
      padding: 32px 24px 43px 24px;
    }
    &__wrapper {
      height: fit-content;
    }
    &__blocks {
      position: relative;
    }
    &__structure {
      gap: 8px;
    }

    &__point {
      max-width: 184px;
      aspect-ratio: 184 / 50;

      &--wide {
        font-size: 12px;
        max-width: 376px;

        aspect-ratio: 376 / 47;
      }
    }
  }
  @media screen and (max-width: 360px) {
    &__block {
      padding: 28px 20px 33px 20px;
    }


    &__point {
      max-width: 132px;
      aspect-ratio: 132 / 46;

      &--wide {
        font-size: 12px;
        max-width: 272px;

        aspect-ratio: 272 / 43;
      }
    }
  }

}

