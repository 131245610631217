.promo {
  position: relative;
  overflow: hidden;

  h1 {
    max-width: 470px;
    text-align: left;
    font-family: Inter;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
    text-transform: capitalize;
    margin: 0 auto 32px auto;
    height: 187px;
    position: relative;
    z-index: 1;

    span {
      display: block;

      &::after {
        display: none !important;
      }
    }
  }

  &__fon {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    content: '';
    background-image: url('../../img/bler-bg-descr.png');
    background-repeat: no-repeat;
    background-size: 1498px 557px;
    background-position: center;
    opacity: 0;
  }

  &__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
    height: fit-content;
    position: relative;
    padding: 100px 60px 90px 90px;
    border-radius: 20px;
    border: 1px solid #242229;
    background: #110E14;
    z-index: 1;
    opacity: 0;
    transform: translateY(30px);

    &::before {
      position: absolute;
      content: '';
      border-radius: 20px;
      border: 1px solid #242229;
      background: #14121B;
      width: calc(100% - 60px);
      height: calc(100% - 70px);
      left: 50%;
      top: 40px;
      transform: translateX(-50%);
      z-index: 0;
      pointer-events: none;
    }
  }

  &__dot--grid {
    position: absolute;
    left: 60px;
    top: 50%;
    transform: translateY(-50%);
    background-image: url('../../img/grid-dot.svg');
    background-repeat: no-repeat;
    background-size: contain;
    width: 560px;
    height: 580px;
    z-index: -1;
    opacity: 0;
    pointer-events: none;
  }

  &__canvas--top {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 45px;
    height: 40px;
    padding-right: 35px;
    z-index: -1;
  }

  &__canvas--center {
    color: #5E5E5E;
    font-size: 10px;
    font-weight: 400;

    span {
      color: #FFF;
    }
  }

  &__canvas--right,
  &__canvas--left {
    transform: translateY(3px);
  }

  &__preview {
    position: relative;
    max-width: 700px;



    video {
      width: 100%;
      max-width: 700px;
      height: auto;
    }
  }

  &__subtitle {
    color: #b0b0b0;
    max-width: 442px;
    /* 1440/Headlines/Slogan Regular */
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    transform: translateY(10px);
    opacity: 0;
  }

  &__btns {
    margin-top: 40px;
    display: flex;
    gap: 20px;
    transform: translateY(10px);
    opacity: 0;
  }
}

.box-anim {
  width: 522px;
  height: 485px;
  opacity: 0;
}

.box-anim-top,
.box-anim-result {
  border-radius: 12px;
  border: 1px solid #2E2B35;
  background: #1B1825;
  transition: all 0.3s ease-in-out;
}

.box-anim-top {
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 20px;

  &.focus {
    border: 1px solid #8664DA;
  }
}

.box-anim-input {
  color: #FFF;
  font-size: 12px;
  font-weight: 600;
  line-height: 100%;
  align-items: center;
  display: flex;

  &:after {
    content: '';
    background-image: url("data:image/svg+xml,%3Csvg width='1' height='26' viewBox='0 0 1 26' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cline x1='0.5' y1='2.18557e-08' x2='0.499999' y2='26' stroke='white'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-size: contain;
    width: 1px;
    height: 26px;
    display: none;
  }
}

.box-anim-btn {
  width: 88px;
  height: 32px;
  color: #1B1B1B;
  font-size: 12px;
  font-weight: 600;
  line-height: 150%;
  position: relative;
  z-index: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  .box-anim-btn--move {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    background: #FFF;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
  }
}

.box-anim-result {
  margin-top: 16px;
  border-radius: 24px;
  height: 413px;
  position: relative;
}

.animation-scane {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  picture {
    display: none;
    overflow: hidden;
  }
}

.first-animation {
  padding: 20px 24px 30px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 12px;

  img {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
    transform: scale(0.2);
    opacity: 0;
  }

  .first-animation--1 {
    grid-column: 1 / 3;
  }
}

.second-animation {
  padding: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}


.second-animation--gif {
  width: 330px;
  height: 330px;
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.02);
  padding: 16px;
  transform: scale(0.2);
  opacity: 0;

  img {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
    border-radius: 12px;
  }
}

.third-animation {
  padding: 20px 32px 30px;

  img {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
    display: block;
    transform: scale(0.6);
    opacity: 0;
  }
}


.third-animation__bottom {
  margin-top: 10px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 12px;
}

.third-animation__col {
  display: flex;
  flex-direction: column;
  gap: 12px;
}




.four-animation__top img,
.four-animation__center img {
  transform: scale(0.1);
  opacity: 0;
  transform-origin: top left;
}

.four-animation__top {
  width: 292px;
  position: relative;
  height: 42px;

  img {
    left: -9px;
    top: -4px;
  }
}

.four-animation__top--text {
  color: #FFF;
  font-size: 28px;
  font-weight: 600;
  line-height: 150%;
}

.four-animation__wrap {
  border-radius: 12px;
  background: #1F1D29;
  padding: 25px 20px 45px 25px;
  transform: scale(0.1);
  opacity: 0;
}

.four-animation__top--text,
.four-animation__center--text {
  &:after {
    display: none !important;
  }
}

.four-animation__center {
  position: relative;
  height: 123px;
  margin-top: 30px;
  width: 322px;

  img {
    left: -8px;
    top: -5px;
    width: 104%;
  }
}

.four-animation__center--text {
  color: #FFF;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  overflow: hidden;

  p {
    margin: 0;
    color: #FFF;
    line-height: 150%;

    &::after {
      display: none !important;
    }
  }
}

.four-animation {
  padding: 35px;

  img {
    width: 103%;
    object-fit: contain;
    position: absolute;
    max-width: unset !important;
    max-height: unset !important;
  }
}


.four-animation__bottom {
  position: relative;
  height: 25.757px;
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 112%;
    top: -16px;
    transform: translateY(20px);
    opacity: 0;
  }
}


.blinking-cursor:after {
  display: inline-block;
}


.animation-scane.hide {
  opacity: 0;
  transition: opacity 0.6s ease-in-out;
}

.animation-scane.show {
  animation: opacity 0.4s ease-in-out forwards;

  .four-animation__wrap,
  .second-animation--gif {
    transform: scale(1) !important;
    opacity: 1 !important;
  }

  .four-animation__bottom img {
    transform: translateY(0) !important;
    opacity: 1 !important;
  }

  img {
    transform: scale(1) !important;
    opacity: 1 !important;
    max-width: 100%;
    max-height: 100%;
  }
}

@keyframes opacity {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.first-animation.show {
  display: grid !important;
}

.second-animation.show {
  display: flex !important;
}

.promo__text--hedden {
  display: none;
}

.animation-scane--mob {
  display: none !important;
}



@media screen and (min-width: 1200px) {
  .promo__canvas--right {
    height: 100%;
    transform: translateY(-7px);

    img {
      max-width: 140%;
      max-height: 140%;
      object-fit: cover;
    }
  }
}


@media screen and (max-width: 1240px) {
  .promo {
    h1 {
      font-size: 36px;
      height: 140px;
      margin-bottom: 16px;
    }

    .promo__subtitle {
      font-size: 16px;
    }
  }

  .promo__text {
    max-width: 390px;
  }
}

@media screen and (max-width: 1200px) {
  .promo__content {
    flex-direction: column-reverse;
    padding: 0;
    padding-top: 34px;
    max-width: 625px;
    margin: 0 auto;
  }



  .promo__fon {}

  .box-anim-top {
    display: none;
  }

  /* .promo__content::before {
    display: none;
  } */

  .promo__dot--grid {
    display: none;
  }

  .promo__text {
    display: none;
  }

  .box-anim-result {
    border: none;
    background: none;
    padding: 10px;
    margin-top: 0;
    height: 490px;
    position: relative;
    overflow: hidden;
    border-radius: 16px;
    background: #14121B;

    &::before {
      position: absolute;
      content: '';
      width: 100%;
      height: 100%;
      border-radius: 16px;
      border: 1px solid #242229;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;
    }
  }

  .box-anim {
    opacity: 1;
    height: 490px;
    width: 590px;
    margin: 0 auto 0;
  }

  .animation-scane {
    pointer-events: none;

    picture {
      display: flex;
      justify-content: center;
      border-radius: 12px;
    }

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  .promo__canvas--top {
    padding: 0 30px;
    height: 27px;

  }

  .promo__canvas--center {
    transform: translateY(4px);
  }

  .promo__canvas--right,
  .promo__canvas--left {
    transform: translateY(6px);
  }

  .promo__canvas--right {
    width: 94px;

    img {
      max-width: 100%;
      max-height: 100%;
      object-fit: cover;
    }
  }

  .promo__canvas--left {
    width: 76px;

    img {
      max-width: 100%;
      max-height: 100%;
      object-fit: cover;
    }
  }

  .promo__canvas--center {
    font-size: 9px;
  }

  .promo__content::before {
    display: none;
  }

  .first-animation {
    padding: 0;
    grid-template-columns: 1fr;

    >img {
      display: none;
    }
  }

  .promo__preview {
    padding-bottom: 20px;
  }

  .promo__text--hedden {
    display: block;
    margin: -75px auto 0 auto;
  }

  .promo__fon {
    background-size: 820px 465px;
    top: 34%;
  }

  .promo__text h1 {
    height: auto;
    max-width: 100%;
  }

  .promo .promo__subtitle,
  .promo__btns {
    opacity: 1;
    transform: translateY(0);
    text-align: center;
  }

  .second-animation--gif {
    display: none;
  }

  .second-animation {
    padding: 0;
  }

  .third-animation {
    padding: 0;
  }

  .four-animation__wrap {
    display: none;
  }

  .four-animation {
    padding: 0;

    img {
      position: static;
      height: 100%;
      width: 100%;
    }
  }

  .animation-scane {

    img {
      max-width: 102% !important;
      max-height: 100% !important;
      width: 102%;
      transform: translateY(-1px) scale(1) !important;
      border-radius: 26px;
    }
  }

  .first-animation img {
    max-width: 100% !important;
    max-height: 91% !important;
    width: 103%;
    transform: translateY(-1px) scale(1) !important;
  }

  .animation-scane--mob {
    display: block !important;
  }

  .third-animation__top,
  .third-animation__bottom {
    display: none;
  }

  .second-animation {
    align-items: flex-start;
  }

  .promo__text {
    position: relative;
    max-width: 625px;
    z-index: 1;

    &::before {
      position: absolute;
      content: '';
      background: linear-gradient(270deg, #110E14 -26.04%, rgba(17, 14, 20, 0.90) 138.96%);
      width: 100vw;
      height: calc(100% + 60px);
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: -1;
    }
  }

  .promo .promo__subtitle {
    max-width: 100%;
    text-align: left;
  }

  .promo .button-main--large {
    width: 100%;
  }

  .promo .button-secondary--large {
    width: 100%;
  }

}

@media screen and (max-width: 1000px) {
  .promo {
    &__content {
      flex-direction: column;
      height: fit-content;
      text-align: center;
      align-items: center;
      gap: 70px;
    }

    &__text {
      z-index: 2;

      h1 {
        max-width: 100% !important;
      }

      p {
        max-width: 344px;
        margin: 0 auto;
      }
    }

    &__preview {
      overflow: visible;
      width: 100%;

      video {
        width: 100%;
        position: relative;
        right: 60px;
      }
    }

    .button-main--large {
      height: 56px;
    }

    .button-secondary--large {
      height: 56px;
    }
  }



}

@media screen and (max-width: 805px) {
  .promo {
    .container {
      padding: 0 20px;
    }

    &__content {
      gap: 64px;
    }

    &__text {
      h1 {
        max-width: 368px;
        font-size: 28px;
        margin: 0 auto 16px auto;
      }

      p {
        max-width: 260px;
        font-size: 14px;
      }
    }

    &__preview {
      video {
        //right: 0;
      }
    }

    &__btns {
      margin-top: 24px;
      gap: 16px !important;
    }

    .button-main--large {
      height: 44px;
    }

    .button-secondary--large {
      height: 44px;
    }
  }


}

@media screen and (max-width: 750px) {
  .promo__content {
    padding-top: 29px;
    max-width: 432px;
  }



  .box-anim-result {
    padding: 10px;
    height: 320px;
  }

  .box-anim {
    height: 320px;
    width: 400px;
  }

  .promo__canvas--top {
    padding: 0 30px;
    height: 27px;
  }

  .promo__canvas--right {
    width: 94px;

    img {
      max-width: 100%;
      max-height: 100%;
      object-fit: cover;
    }
  }

  .promo__canvas--left {
    width: 76px;

    img {
      max-width: 100%;
      max-height: 100%;
      object-fit: cover;
    }
  }

  .promo__text {
    max-width: 432px;
  }

  .promo__canvas--center {
    font-size: 9px;
    transform: translateY(0);
  }


  .promo__preview {
    padding-bottom: 20px;
  }

  .promo__fon {
    background-size: 562px 298px;
  }

  .promo__text--hedden {
    margin: -34px auto 0 auto;
  }

  .promo {
    overflow: visible;
  }

  .promo__canvas--right,
  .promo__canvas--left {
    transform: translateY(3px);
  }

}

@media screen and (max-width: 480px) {
  .promo {
    &__btns {
      .button-secondary {
        font-size: 14px;
        line-height: 100%;
      }
    }

    .container {
      padding: 0 20px;
    }

    .btn-main {
      width: 200px;
      height: auto;
    }

    &__preview {
      width: 100%;

      video {
        width: 100%;
      }
    }

    &__text {
      margin-top: -40px;


      h1 {
        font-size: 36px;
        max-width: 100% !important;
        text-align: left;
      }
    }

    .btn-secondary {
      width: 200px;
      height: auto;
    }

    &__btns {
      margin-top: 28px;
      flex-direction: column;
    }
  }


  .promo__fon {
    background-image: url('../../img/bler-bg.png');
    background-size: contain;
    height: 562px;
    top: 24%;
  }



}


@media screen and (max-width: 460px) {
  .box-anim-result {
    width: 288px;
    margin: 0 auto;
  }

  .box-anim {
    width: unset;
    height: 250px;
  }

  .promo__canvas--center {
    display: none;
  }

  .promo__canvas--top {
    padding: 0 25px;
  }

  .promo .promo__subtitle {
    font-size: 14px;
  }

  .promo::before {
    height: 350px;
    top: -78px;
  }

  .box-anim-result {
    height: 100%;
  }

  .promo__text h1 {
    font-size: 26px;
  }

  .promo__content {
    max-width: 312px;
  }

  .promo__text {
    margin-top: -45px;
  }
}

@media screen and (max-width: 360px) {
  .promo {
    &__btns {
      .button-secondary {
        font-size: 12px;

      }
    }

    .button-main--large {
      height: 40px;
    }

    .button-secondary--large {
      height: 40px;
    }

    &__content {
      gap: 55px;
    }

    &__text {
      h1 {
        font-size: 20px;
        max-width: 260px;
      }

      p {
        font-size: 12px;
        max-width: 232px;
      }
    }
  }
}