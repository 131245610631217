.info {
  padding: 0 23px 0 23px;

  &__head-title {
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    margin-bottom: 16px;
  }

  &__btns {
    margin: 0 auto;
    width: fit-content;
  }

  &__subtitle {
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    color: #b0b0b0;
    text-align: center;
    max-width: 360px;
    margin: 0 auto;
  }

  &__text {
    text-align: center;

    h3 {
      margin-bottom: 32px;
    }
  }

  &__btns {
    .button-secondary--large {
      width: 175px;
      height: 56px;
    }
  }

  &__list {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 48px 0 0 0;
    gap: 20px;
  }

  &__item {
    display: flex;
    flex-direction: column;
    // justify-content: space-between;

    background: #221d29;
    border-radius: 12px;
    max-width: 400px;
    padding: 40px 0 0 0;
    position: relative;

    &:nth-child(1) {
      .info__preview {
        width: 410px;
        padding: 0;
        position: relative;
        top: 24px;

        img {
          width: 100%;
        }
      }
    }

    &:nth-child(2) {
      .info__preview {
        padding-top: 32px;
        margin: auto 0 0 0;
        line-height: 0;
        overflow: hidden;

        img {
          box-shadow: 0px 293px 82px rgba(0, 0, 0, 0.01),
            0px 187px 75px rgba(0, 0, 0, 0.05),
            0px 105px 63px rgba(0, 0, 0, 0.15),
            0px 47px 47px rgba(0, 0, 0, 0.26), 0px 12px 26px rgba(0, 0, 0, 0.3),
            0px 0px 0px rgba(0, 0, 0, 0.31);
        }
      }
    }

    &:nth-child(3) {
      .info__preview {
        padding: 32px 0 0 0;
      }
    }
  }

  &__preview {
    padding: 0 40px;
  }

  &__title {
    padding: 0 40px;
    font-weight: 600;
    font-size: 18px;
    line-height: 140%;

    color: #ffffff;
    padding-bottom: 12px;
  }

  &__desc {
    padding: 0 72px 0 40px;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;

    color: #b0b0b0;
  }

  &__preview {
    width: 100%;

    img {
      width: 100%;
    }
  }

  &__keywords {
    display: flex;
    flex-wrap: wrap;
    gap: 12px 10px;
    margin: 43px 0 0 0;
    padding: 0 40px;
  }

  &__keyword {
    padding: 12px 0;
    width: 100px;
    text-align: center;
    gap: 1px;
    flex-shrink: 1;
    font-weight: 600;
    font-size: 12px;
    line-height: 100%;

    border-radius: 1000px;

    color: #5E5E5E;
    border: 1px solid #393939;
    background:  #221d29;
    /* Super Dark */
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.31),
      0px 12px 26px 0px rgba(0, 0, 0, 0.3),
      0px 47px 47px 0px rgba(0, 0, 0, 0.26),
      0px 105px 63px 0px rgba(0, 0, 0, 0.15),
      0px 187px 75px 0px rgba(0, 0, 0, 0.05),
      0px 293px 82px 0px rgba(0, 0, 0, 0.01);
  }
}

@media screen and (max-width: 1250px) {
  .info {
    &__item {
      overflow: hidden;
      max-width: 267px;
      padding: 32px 0 0 0;

      &:nth-child(1) {
        .info__preview {
          width: 270px;
          //                    left: -30px;
          //                    padding: 30px 24px 0 24px;
        }
      }

      &:nth-child(2) {
        .info__preview {
          padding: 0 24px 0 24px;
          position: relative;
          //                    overflow: hidden;

          img {
            position: relative;

            right: 0;
          }
        }
      }

      &:nth-child(3) {
        .info__preview {
        }
      }
    }

    &__title {
      padding: 0 24px 12px 24px;
      font-size: 16px;
    }

    &__desc {
      padding: 0 24px;
      font-size: 12px;
      line-height: 120%;
    }

    &__keywords {
      margin: 24px 0 0 0;
      padding: 0 0 0 24px;
      gap: 6px;
    }

    &__keyword {
      font-size: 10px;
      width: 75px;
      font-size: 10px;
      padding: 8px 0;

      margin: 0;
    }
  }
}
@media screen and (max-width: 1000px) {
  .info {
    &__btns {
      .button-secondary--large {
        width: 220px;
        height: 56px;
      }
    }
  }
}
@media screen and (max-width: 830px) {
  .info {
    padding: 0 23px 0 23px;
    &__list {
      flex-direction: column;
      align-items: center;
    }

    &__desc {
      width: 80%;
      line-height: 150%;
    }

    &__item {
      max-width: 424px;

      &:nth-child(1) {
        height: 370px;

        .info__preview {
          width: 370px;
          left: 24px;
          top: 24px;
          //                    width: 100%;

          //                    position: relative;
          //                    margin: 36px 0 0 0;
          //                    padding: 0;
          //                    overflow: hidden;

          img {
            //                        position: absolute;
            //                        top: 0;
            //                        left: 0;
            //                        padding: 0 28px;
          }
        }
      }

      &:nth-child(2) {
        .info__preview {
          max-width: 100%;
          padding: 0;
          display: flex;
          justify-content: center;
          margin: 28px auto 0 auto;

          img {
            max-width: 247.09px;

            top: 0px;
          }
        }
      }

      &:nth-child(3) {
        .info__preview {
          // padding: 0 0 30px 0;
          max-width: 310px;
          margin: 0 auto;
        }
      }
    }

    &__keywords {
      margin: 24px 0;
      padding: 0 24px;
    }

    &__keyword {
      width: 88px;
      padding: 10px 0;
      font-size: 11px;

      &:last-child {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 786px) {
  .info {
    &__head-title {
      font-size: 20px;
      margin-bottom: 12px;
    }

    &__subtitle {
      max-width: 260px;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
    }
    &__title {
      padding: 0 24px 8px 24px;
    }

    &__content {
    }
    &__btns {
      .button-secondary--large {
        width: 180px;
        height: 44px;
      }
    }
  }

  @media screen and (max-width: 480px) {
    .info {
      &__head-title {
        font-size: 18px;
        margin-bottom: 12px;
      }

      &__subtitle {
        font-size: 14px;
      }

      &__btns {
        .button-secondary--large {
          width: 180px;
          height: 44px;
        }
      }
      &__desc {
        width: 100%;
      }

      &__item {
        max-width: 282px;
        // padding: 32px 0 70px 0;
        padding: 32px 0 0 0;

        &:nth-child(1) {
          // height: 325px;
            height: fit-content;
            padding: 32px 0 24px 0;
          .info__preview {
            top: 24px;
            left: 0;

            width: 300px;

            img {
            }
          }
        }

        &:nth-child(2) {
          .info__preview {
            max-width: 100%;
            padding: 0 24px;
            img {
              max-width: 100%;
              top: 0;
            }
          }
        }

        &:nth-child(3) {
          padding: 32px 0 0 0;

          .info__preview {
            padding: 0;
            max-width: 100%;
          }
        }
      }

      &__title {
        font-size: 14px;
      }

      &__desc {
        width: 100%;
      }

      &__keywords {
        gap: 10px 6px;
        padding: 0 20px;
      }

      &__keyword {
        width: 75px;
        width: 75px;
        padding: 10px 0;
        font-size: 10px;

        &:last-child {
          display: block;
        }
      }
    }
  }

  @media screen and (max-width: 360px) {
    .info {
      &__subtitle {
        font-size: 12px;
      }
      &__content .btn-main--dark {
        width: 230px;
        height: auto;

        padding: 15px 0;
      }
      &__text {
        h3 {
          margin-bottom: 24px;
        }
      }
      &__btns {
        .button-secondary--large {
          width: 230px;
          height: 40px;
        }
      }

      &__item {
        max-width: 312px;
        // padding: 32px 0 70px 0;
        // &:nth-child(1) {
        //   .info__preview {
        //     width: 100%;

        //     img {
        //     }
        //   }
        // }

        // &:nth-child(2) {
        //   .info__preview {
        //   }
        // }

        // &:nth-child(3) {
        //   padding: 32px 0 50px 0;

        //   .info__preview {
        //     // padding: 0 6px 0 30px;
        //   }
        // }
      }

      &__title {
        font-size: 14px;
      }

      &__desc {
        // width: 80%;
      }

      &__keywords {
        // padding: 0 20px;
      }

      &__keyword {
        width: 75px;
        font-size: 10px;

        &:last-child {
          display: block;
        }
      }
    }
  }
}

@media screen and (max-width: 360px) {
  .info {
    &__text {
      p {
        font-size: 12px;
      }
    }
  }
}
