.pricing {
  text-align: center;
  border-radius: 20px;
  padding: 0 24px;

  &__account-title {
    font-size: 28px!important;
  }

  &__subtext {
    font-family: Inter;
    padding-top: 20px;
    font-weight: 300; 
    font-size: 16px;
    line-height: 140%;
    max-width: 470px;
    text-align: center;

    color: #B0B0B0;
  }
  &__account-sub-text {
    padding-top: 48px;
    max-width: 400px;
  }

  &__plans {
    display: flex;
    justify-content: center;

  }
  &__content {

    //max-width: 1240px;
   &-bg {
     border-radius: 20px;
     border: 1px solid #2E2B35;
     background: #120F17;
     width: 100%;
     padding: 80px 0px 96px 0px;
     @media screen and (max-width: 400px){

       border: none;
       background: transparent;
       width: 100%;
       padding: 0;
     }
   }
  }


  &__plan-title {
    text-align: left;
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 100%;


    color: #f3f3f3;
  }

  &__email {
    padding-top: 16px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    /* or 22px */

    text-align: center;

    /* Dark 600 */

    color: #727272;
  }

  &__price-row {
    margin: 28px 0 40px 0;
    display: flex;
    gap: 8px;
    align-items: flex-end;
  }

  &__plan-price {
    text-align: left;
    font-weight: 600;
    font-size: 40px;
    line-height: 100%;


    color: #f3f3f3;
  }
}

.pricing .pricing__plan-duration {
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  /* or 22px */

  text-align: center;

  /* Dark 1000 */

  color: #f3f3f3;
}

.pricing__head {
  // background: rgba(107, 69, 216, 0.03);

}

.pricing__list {
  padding: 0 30px 60px 30px;
  display: flex;
  flex-direction: column;
  gap: 28px;
}

.pricing__list li {
  display: flex;
  align-items: center;
  text-align: left;
  font-weight: 600;
  border-bottom: 1px solid #382471;
  padding-bottom: 16px;
  font-size: 14px;
  line-height: 140%;
  /* identical to box height, or 14px */

  /* Dark 50 */

  color: #f3f3f3;
}

.pricing__list svg {
  margin-right: 10px;
}

.pricing__toggles {
  position: relative;
  display: flex;
  gap: 16px;
  align-items: center;
  width: fit-content;
  margin: 44px auto;
}

.prices__save {
  position: absolute;
  top: -10px;

  right: -160px;

  &-360 {
    display: none;
  }
}


.pricing__toggle {
  position: relative;
  transition: linear 0.2s;
  cursor: pointer;
  padding: 3px;
  width: 65px;
  height: 34px;
  border-radius: 40px;
  background: #201C29;
  margin: 0 auto;
  left: 0;


}

.pricing__toggle--active {
  background: #5b62ff;

}

.pricing__toggle-ball {
  margin: auto 0;
  position: relative;
  transition: linear 0.2s;
  width: 28px;
  height: 28px;
  border-radius: 100px;
  background: #fbfbfb;
  top: 0;
  bottom: 0;
  left: 1px;
}

.pricing__toggle--active .pricing__toggle-ball {
  left: 30px;
}


.toggle__text {


  transition: linear 0.3s;
  color: #B0B0B0;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;


  &--active {

    color: #fff;
  }


}


//Plan


.plan {
  flex: 1;
  max-width: 420px;
  position: relative;
  background: #6B45D7;
  border-radius: 12px;
  padding: 40px 32px 64px 32px;
  overflow: hidden;

  &__annual {
    top: 20px;
    right: -40px;
    transform: rotate(43deg);
    position: absolute;
    width: 170px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #5b62ff;
    color: #ffffff;
    font-weight: 600;
    font-size: 12px;
    line-height: 100%;
  }

  &__head {
    display: flex;
    flex-direction: column;
    gap: 40px;
    margin: 36px 0 44px 0;

    .btn-main--light {
      width: 100%;
      height: 48px;
      color: #393939;
      font-style: normal;
      font-weight: 600;
      line-height: 100%;
      box-shadow: 0px 0px 0px 0px rgba(45, 13, 94, 0.10), 0px 3px 6px 0px rgba(45, 13, 94, 0.10), 0px 11px 11px 0px rgba(45, 13, 94, 0.09), 0px 25px 15px 0px rgba(45, 13, 94, 0.05), 0px 45px 18px 0px rgba(45, 13, 94, 0.01), 0px 71px 20px 0px rgba(45, 13, 94, 0.00);
    }
  }

  &__row {
    display: flex;
    gap: 8px;
    align-items: flex-end;
  }

  &__price {
    text-align: left;
    font-weight: 600;
    font-size: 40px;
    line-height: 100%;
    color: #f3f3f3;
  }


  &__duration {
    font-weight: 500;
    font-size: 18px;
    line-height: 140%;
    text-align: center;
    color: #f3f3f3;
  }

  &__cta {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 6px;
    color: #FFF;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 21px */
    text-decoration-line: underline;
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  &__features {
    display: flex;
    gap: 8px;
    align-items: center;
    color: #FFF;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    padding-bottom: 16px;
    border-bottom: 1px solid #8356FF;
  }

}


@media screen and (max-width: 1000px) {
  .pricing {
    &__account-title {
      font-size: 24px!important;
    }
    &__content {
      padding: 72px 0px 88px 0px;
    }
    &__subtext {
      max-width: 400px;
    }
  }


}


@media screen and (max-width: 786px) {

  .pricing {
    &__account-title {
      font-size: 20px!important;
    }
    &__toggles {
      margin: 40px auto;
    }
    &__content {
      padding: 48px 0px 64px 0px;
    }
    &_subtext {
      max-width: 350px;
    }
  }
  .plan {
    padding: 40px 32px 48px 32px;
    &__cta {
      font-size: 12px;
    }

    &__head {
      gap: 32px;
      margin: 36px 0;
      .btn-main--light {
        font-size: 14px;
        height: 44px;
      }
    }

    &__price {
      font-size: 36px;
    }

    &__duration {
      font-size: 16px;

    }
    &__list {
      gap: 24px;
    }
    &__features {
      padding-bottom: 12px;
    }
  }
  .toggle {
    &__text {
      font-size: 14px;
    }
  }
  .pricing {
    &__toggle {
      width: 58px;
      height: 30px;

      &--active {
        .pricing__toggle-ball {
          left: 26px;
        }
      }

      &-ball {
        left: 2px;
        width: 25px;
        height: 25px;

      }
    }

    &__account-sub-text {
      padding-top: 48px;
    }
    &__subtext {
      padding-top: 16px;
      font-size: 14px;
    }
  }
}
@media screen and (max-width: 600px) {
  .prices__save {
    width: 116px;
    left: 175px;
    top: -24px;

    svg {
      width: 100%;
    }
  }
}
@media screen and (max-width: 520px) {
  .pricing__subtext {
    max-width: 300px;

  }
  .prices__save {
    width: 116px;
    left: 175px;
    top: -24px;

    svg {
      width: 100%;
    }
  }


  .pricing {


    &__subtext {
      font-size: 12px;
      width: 320px;
    }
  }
}

@media screen and (max-width: 480px) {
  .pricing {
    &__content {
      padding: 40px 0px 56px 0px;
    }
    &__account-title {
      font-size: 18px!important;
    }
    &__email {
      font-size: 12px;
      padding-top: 8px;
    }
    &__toggles {
      margin: 32px auto;
      gap: 19.5px;
    }

    &__toggle {
      width: 48px;
      height: 26px;
      &__text {
        font-size: 12px;
      }
      &--active {
        .pricing__toggle-ball {
          left: 22px;
        }
      }

      &-ball {
        left: 0;
        width: 20px;
        height: 20px;

      }
    }
  }
  .plan {
    max-width: 320px;
    padding: 40px 28px;
    &__cta {
      height: 17px;
      font-size: 12px;
    }
    &__head {
      margin: 32px 0;
      gap: 28px;
      .btn-main--light {
        height: 40px;
      }
    }
    &__price {
      font-size: 32px;
    }
    &__duration {
      font-size: 12px;
    }
    &__list {
      gap: 16px;
    }
    &__features {
      padding-bottom: 16px;
      font-size: 12px;
    }
  }
}

@media screen and (max-width: 400px) {
  .prices__save {
    width: 108px;
    left: 155px;
    top: -24px;

    svg {
      width: 100%;
    }
  }
}
@media screen and (max-width: 360px) {
  .pricing {
    &__toggles {
      gap: 17.5px;
    }

  }
  .plan {
    max-width: 312px;
    &__list {
      gap: 12px;
    }
  }
}

@media screen and (max-width: 440px) {
  .prices__save {
    width: 90px;
    left: 160px;
    top: -24px;

    svg {
      width: 100%;
    }
  }
}


