.sign {
  padding: 0 24px;

  &__container {
    max-width: 1080px;
    margin: 0 auto;
  }

  &__content {
    min-height: 100vh;
    display: flex;
    justify-content: space-between;
    //align-items: center;
    padding: 120px 0 240px 0;

  }

  &__toggle {
    position: relative;
    display: flex;
    justify-content: space-between;
    width: 250px;
    margin: 0 auto 56px auto;
    padding-bottom: 20px;
    border-bottom: 1px solid #1F1D29;


  }

  &__tab {
    cursor: pointer !important;
    color: #9E9E9E;
    font-size: 28px;
    font-weight: 600;
    line-height: 100%;

    &-active {
      color: #FFF;
    }
  }

  &__runner {
    transition: linear 0.2s;
    position: absolute;
    bottom: 0;

    width: 100px;
    height: 1px;
    background: #FFF;
  }

  &__separator {
    margin: 56px 0;
    width: 100%;
    position: relative;

    &::before {
      position: absolute;
      content: '';
      top: 0;
      bottom: 0;
      margin: auto;
      left: 0;
      right: 0;
      height: 1px;
      width: 100%;
      background: #1F1D29;
      z-index: 99;


    }

    span {
      position: relative;
      padding: 0 8px;
      z-index: 100;
      width: fit-content;
      margin: 0 auto;
      color: #FFF;
      background: #000000;

      display: block;
      margin: 0 auto;

      font-size: 14px;

      font-weight: 400;
      line-height: 150%;
    }
  }

  &__form {
    &-inputs {
      display: flex;
      flex-direction: column;
      gap: 24px;
    }

    .btn-main {
      margin: 40px 0 0 0;
    }
  }


  &__column-left {
    width: 100%;
    max-width: 453px;
    padding-top: 104px;

  }

  &__title {
    font-size: 36px;
    font-weight: 600;
    line-height: 150%;
    text-align: left;
    margin: 0 0 20px 0;
  }

  &__subtitle {
    font-size: 16px;
    font-weight: 400;
    line-height: 150%;
  }

  &__column-right {
    width: 100%;
    max-width: 505px;

  }

  &__forgot {
    cursor: pointer;
    display: block;
    width: fit-content;
    margin: 24px auto 0 auto;
    color: #FFF;
    font-size: 14px;
    font-weight: 600;
    line-height: 150%;
    text-decoration-line: underline;
    &:hover {
      opacity: 0.8;
    }
  }
}

@media screen and (max-width: 1000px) {
  .sign {
    &__column-left {
      display: none;
    }

    &__column-right {
      margin: 0 auto;
    }
  }
}

@media screen and (max-width: 786px) {
  .sign {
    &__toggle {
      width: 215px;
      margin: 0 auto 48px auto;
    }

    &__tab {
      font-size: 24px;
    }

    &__separator {
      margin: 48px 0;
    }

    &__form {
      .btn-main {
        margin: 32px 0 0 0;
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .sign {

    &__forgot {
      font-size: 12px;
    }
  }
}
@media screen and (max-width: 360px) {
  .sign {
    &__toggle {
      width: 179px;
    }
   &__tab {
     font-size: 20px;
   }
  }
}