.bento-gc {
  &__block {
    padding: 40px 32px 40px 32px;
    //background: url('../../../../img/bendo/block_bg.png') 0 0 / 400px 340px repeat;
    background: #120F17;
    border: 1px solid #2E2B35;
    border-radius: 20px;
  }

  &__img {
    max-width: 548px;
    aspect-ratio: 548 / 147;
    background: url('../../../../img/bendo/gc-01.svg') 0 0 / contain no-repeat;
  }

  &__btns {
    display: flex;

    gap: 17px;
    margin-top: 24px;
  }

  &__keyword {
    color: #D6D6D6;
    font-size: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    aspect-ratio: 96 / 32;
    max-width: 96px;
    width: 100%;
    border-radius: 100px;
    border: 1px solid rgba(72, 72, 72, 0.50);
    background: #1F1D29;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.31), 0px 12px 26px 0px rgba(0, 0, 0, 0.30), 0px 47px 47px 0px rgba(0, 0, 0, 0.26), 0px 105px 63px 0px rgba(0, 0, 0, 0.15), 0px 187px 75px 0px rgba(0, 0, 0, 0.05), 0px 293px 82px 0px rgba(0, 0, 0, 0.01);
  }

  @media screen and (max-width: 1000px) {
    &__img {
      max-width: 364px;
      aspect-ratio: 364 / 160;
      background: url('../../../../img/bendo/gc-02.svg') 0 0 / 100% no-repeat;
    }
    &__btns {
      // flex-wrap: wrap;
      gap: 8px;
      justify-content: flex-start;
    }
    &__keyword {
      width: 100%;
      max-width: 66.4px;
      //height: 24px;
      aspect-ratio: 66.4 / 24;
      font-size: 8px;
    }
  }


  @media screen and (max-width: 786px) {
  
    &__block {
      padding: 32px 18px 27px 20px;
      .bento__title-h4 {
        padding-bottom: 24px;
      }
    }
    &__img {
      max-width: 266px;
      aspect-ratio: 266 / 158;
      background: url('../../../../img/bendo/gc-03.svg') 0 0 / contain no-repeat;
    }

    &__btns {
      margin-top: 20px;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: 1fr 1fr;

    }

    &__keyword {
      font-size: 9px;
      width: 100%;
      max-width: 80px;
      aspect-ratio: 80 / 27;

    }

  }


  @media screen and (max-width: 560px) {
    &__img {
      max-width: 384px;
      aspect-ratio: 384 / 192;
      background: url('../../../../img/bendo/gc-04.svg') 0 0 / contain no-repeat;
    }

    &__btns {
      display: flex;
      margin-top: 25px;
    }

    &__keyword {
      width: 100%;
      max-width: 70px;
      aspect-ratio: 70 / 27;
    }

  }

  @media screen and (max-width: 450px) {
    &__btns {
      flex-wrap: wrap;
    }

  }

  @media screen and (max-width: 360px) {
    &__block {
      padding: 28px 20px 24px;
    }
    &__img {
      max-width: 272px;
      aspect-ratio: 272 / 157;
      background: url('../../../../img/bendo/gc-05.svg') 0 0 / contain no-repeat;
    }
    &__btns {

      gap: 6px;
      flex-wrap: nowrap;
      margin-top: 20px;
    }
    &__keyword {
      max-width: 64px;
      aspect-ratio: 64 / 27;
      &:last-child {
        display: none;
      }
    }
  }
}