.problem-solving {
  margin-top: 300px;
  height: 100vh;
  background-color: #1b1b1b;
  &__row {
    height: 500px;
    width: fit-content;
    gap: 48px;
    margin: 0 auto;
    display: flex;
    justify-items: center;
    &:nth-child(2) {
      .problem-solving__right {
        background-color: #c41b9a;
      }
    }
    &:nth-child(3) {
      .problem-solving__right {
        background-color: #c49d1b;
      }
    }
  }
  &__left {
    max-width: 400px;
    h1,p {
      text-align: left;
    }
  }
  &__right {
    flex-shrink: 0  ;
    width: 300px;
    height: 300px;
    background-color: #1bc440;
    border-radius: 25px;
  }


}