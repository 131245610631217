//Buttons

.btn-main {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
  width: 260px;
  gap: 8px;
  cursor: pointer;

  border-radius: 12px;

  transition: background 0.3s ease-in-out, color 0.3s ease-in-out,
  border 0.3s ease-in-out, transform 0.3s ease-in-out;

  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 100%;
  /* identical to box height, or 16px */

  text-align: center;

  /* White FFFFFF */

  color: #ffffff;
  @media screen and (max-width: 1000px) {
    border-radius: 8px;
  }
  &--dark {
    background: #4b4b4b;

    &:hover {
      background: #5e5e5e;
    }
  }

  &--purple {
    background: #6B45D7;

    &:hover {
      transform: scale(1.01);
      background: #815CE9;
    }
  }

  &--light {
    background-color: #ffffff;
    color: #111111;

    &:hover {
      transform: scale(1.01);
    }
  }

  &__violet {
    background-color: #6b45d7;

    &:hover {
      background-color: #815ce9;
    }
  }

  &--large {
    width: 100%;
  }

  &--login {
    background: #111111;
  }
}

.btn-secondary {
  /* Auto layout */

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 24px 28px;
  gap: 10px;

  width: 250px;
  height: 56px;

  /* Dark 800 */

  border-radius: 1000px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  transition: background 0.3s ease-in-out, color 0.3s ease-in-out,
  border 0.3s ease-in-out;

  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 100%;
  /* identical to box height, or 16px */

  text-align: center;
  cursor: pointer;

  &--very-small {
    padding: 11px 0;
    width: 86px;
    font-size: 14px;
    height: auto;
  }

  &--large {
    width: 100%;
  }

  &--small {
    padding: 14px 0;
    width: 120px;
    font-size: 14px;
  }

  &--light {
    color: #ffffff;
    border: 1px solid #4b4b4b;

    &:hover {
      border: 1px solid #727272;
    }
  }

  &--light-with-ico {
    background: #E7E7E7;
    border-radius: 12px;
    transition: background 0.3s ease-in-out, color 0.3s ease-in-out, border 0.3s ease-in-out, transform 0.3s ease-in-out;
    &:hover {
      transform: scale(1.01);
    }
  }

  &--dark {
    color: #111111;
    border: 1px solid #727272;

    &:hover {
      border: 1px solid #727272;
    }
  }


  &--dark-with-ico {
    color: #111111;
    border: 1px solid #727272;

    &:hover {
      background: #f3f3f3;

      border: 1px solid #111111;
    }
  }

  &--large {
    width: 100%;
  }
}
.btn-secondary--light-with-ico {
  @media screen and (max-width: 1000px) {

    border-radius: 8px
  }
}

@media screen and (max-width: 786px) {
  .btn-secondary {
    height: 44px;
    padding: 0;
    font-size: 14px;
  }
  .btn-main {
    height: 44px;
    padding: 0;
    font-size: 14px;
  }
}

@media screen and (max-width: 480px) {
  .btn-main {
    font-size: 12px;
  }

  .btn-secondary {
    font-size: 12px;

    &--very-small {
      padding: 9px 0;
    }
  }
}

//Main 
@import './components/_footer';

//Landing
@import "./landing/_promo";
@import "./landing/_price";
@import "./landing/_info";
@import "./landing/_faq";
@import "./auth/_auth";
@import "./_buttons";
@import "./profile/_profile";

#root {
  //  background: url('../../pattern.svg') center center / contain repeat-y, #110e14;

  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: space-between;
  background: #000000;
}

@media screen and (max-width: 480px) {
  #root {
    //    background: url('../img/pattern-mob.svg') center center / contain repeat-y,
    //      #110e14;
  }
}

.btn-loading {
  //border: 1px solid #c6c6c6;
  //color: rgba(17, 17, 17, 0.5);
  //
  //&:hover {
  //  border: 1px solid #c6c6c6;
  //  color: rgba(17, 17, 17, 0.5);
  //  background: inherit;
  //}
}


.privacy {
  h1 {
    font-weight: 600;
    font-size: 24px;
    line-height: 140%;
    text-align: left;
    color: #ffffff;
    margin: 0;
  }
}

.landing {
  padding: 40px 0 164px 0;
  display: flex;
  flex-direction: column;
  gap: 164px;
  overflow: hidden;
}

@media screen and (max-width: 1000px) {
  .landing {
    padding: 40px 0 112px 0;
    gap: 120px;
  }
}

@media screen and (max-width: 786px) {
  .landing {
    padding: 40px 0 104px 0;
    gap: 104px;
  }
}

@media screen and (max-width: 480px) {
  .landing {
    padding: 40px 0 112px 0;
    gap: 96px;
  }
}

@media screen and (max-width: 360px) {
  .landing {
    padding: 40px 0 96px 0;
    gap: 80px;
  }
}