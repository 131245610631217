.forgot {
  min-height: 100vh;
  padding-top: 80px;
  &__content {
    margin: 0 auto;
    max-width: 476px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .btn-main {
      margin-top: 40px;
    }

  }
  &__ico {
    margin: 0 auto 20px auto;
  }
  &__create {
    max-width: 391px;
    color: #FFF;
    padding: 20px 0 0 0;

    font-size: 12px;
    font-weight: 400;
    line-height: 150%;
    &-link {
      color: #7F7CFF;
      font-size: 12px;
      font-weight: 400;
      line-height: 150%;
      text-decoration-line: underline;
      &:hover {
        opacity: .8
      }
    }
  }
  .sign {
    &__title {
      text-align: center;
      color: #FFF;
    }
    &__subtitle {
      text-align: center;

      padding: 0 0 64px 0;
    }
  }

}

.recovery {
  &__title {
    margin: 0 0 64px 0;
  }
}