.bento-gup {
  &__block {
    padding: 40px 32px 0 32px;
    //background: url('../../../../img/bendo/block_bg.png') 0 0 / 400px 340px repeat;
    background: #120F17;
    border: 1px solid #2E2B35;
    border-radius: 20px;
  }

  &__gup-img {
    position: relative;
    aspect-ratio: 250 / 326;


    background: url('../../../../img/bendo/GenerateUserPersonas.png') 0 0 / contain no-repeat;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.31), 0px 12px 26px 0px rgba(0, 0, 0, 0.30), 0px 47px 47px 0px rgba(0, 0, 0, 0.26), 0px 105px 63px 0px rgba(0, 0, 0, 0.15), 0px 187px 75px 0px rgba(0, 0, 0, 0.05), 0px 293px 82px 0px rgba(0, 0, 0, 0.01);
  }

  @media screen and (max-width: 1000px) {
    &__gup-img {
      aspect-ratio: 364 / 256;

      background: url('../../../../img/bendo/GUP-02.png') 0 0 / contain no-repeat;
    }

    &__block {
      padding: 36px 32px 0 32px;
    }
  }

  @media screen and (max-width: 786px) {
    &__block {
      padding: 32px 24px 0 24px;
    }
    &__gup-img {
      aspect-ratio: 264 / 257;
      background: url('../../../../img/bendo/GUP-03.png') 0 0 / contain no-repeat;
    }
  }
  @media screen and (max-width: 560px) {
    &__block {
      padding: 32px 24px 0 24px;
    }
    &__gup-img {
      max-width: 320px;
      aspect-ratio: 320 / 276;
      margin: 0 auto;

      background: url('../../../../img/bendo/GUP-04.png') 0 0 / contain no-repeat;
    }
  }
  @media screen and (max-width: 380px) {
    &__block {
      padding: 28px 20px 0 20px;
    }
    &__gup-img {
      aspect-ratio: 278 / 238;

      background: url('../../../../img/bendo/GUP-05.png') 0 0 / contain no-repeat;
    }
  }
}