.bento-gmm {
  &__block {
    overflow: hidden;
    //background: url('../../../../img/bendo/block_bg.png') 0 0 / 400px 340px repeat;
    background: #120F17;
    padding: 40px 0 0 32px;
    border: 1px solid #2E2B35;
    border-radius: 20px;
    position: relative;
  }


  &__img {
    bottom: -20px;
    left: 0;
    width: 100%;
    position: absolute;
    max-width: 298px;
    aspect-ratio: 298 /325;

    background: url('../../../../img/bendo/gmm.svg') 0 0 / contain no-repeat;

  }

  @media screen and (max-width: 1000px) {
    &__img {
      max-width: 428px;
      aspect-ratio: 428 /295;
      bottom: -30px;
      background: url('../../../../img/bendo/gmm-2.svg') 0 0 / contain no-repeat;
    }
  }

  @media screen and (max-width: 786px) {
    &__block {
      padding: 32px 0 0 24px;
     
    }

    &__img {
      max-width: 311px;
      aspect-ratio: 311 /262;
      bottom: 0;
      background: url('../../../../img/bendo/gmm-3.svg') 0 0 / contain no-repeat;
    }
  }
  @media screen and (max-width: 560px) {
    &__title {
      padding: 36px 0 0 32px;
    }
    &__img {
      max-width: 432px;
      aspect-ratio: 432 /360;
      bottom: -70px;
      background: url('../../../../img/bendo/gmm-480.svg') 0 0 / contain no-repeat;
    }
  }

  @media screen and (max-width: 480px) {
    &__block {
      padding: 28px 0 0 0;

      .bento__title-h4 {
        padding: 0 0 30px 20px;
      }
    }
    &__title {

    }
    &__img {
      position: relative;
      max-width: 100%;
      aspect-ratio: 311 / 239;
    bottom: 0;


      background: url('../../../../img/bendo/gmm-05.svg') 0 0 / contain no-repeat;
    }
  }

}