.input {
  width: 100%;
  position: relative;

  input {
    width: 100%;

    padding: 20px 24px;
    border-radius: 12px;
    background: #17141E;
    color: #B0B0B0;
    font-size: 14px;
    font-weight: 500;
    line-height: 150%;
    border: none;
    transition: linear 0.05s;

    &:focus {
      background: #2D2639;
    }

  }

  &-error {

    input {
      border: 0.5px solid #DE1212;
    }
  }

  &-error-message {
    position: absolute;
    content: attr(data-error);
    top: -10px;
    right: 15px;
    font-weight: 600;
    font-size: 10px;
    line-height: 140%;
    color: #DE1212;
    background: #ffffff;

    border-radius: 1000px;
    padding: 8px 10px;

    pointer-events: none;
  }

  &__valid {
    position: absolute;
    top: 20px;
    right: 24px;
  }

}


@media screen and (max-width: 786px) {
  .input {
    input {
      height: 48px;
      padding: 20px 18px;
      font-size: 12px;
    }
  }
  @media screen and (max-width: 480px) {
    .input {
      input {
        height: 44px;
        padding: 13px 18px;
        font-size: 12px;
      }
    }
  }
}