.button-main {
  border-radius: 1000px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  transition: ease-in-out 0.3s;
  &--puprle {
    color: #ffffff;
    background-color: #6b45d7;
    &:hover {
      background-color: #815ce9;
    }
  }
  &--large {
    width: 180px;
    height: 56px;
  }
}

.button-secondary {
  border-radius: 1000px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  transition: ease-in-out 0.3s;
  &--dark {
    color: #ffffff;
    background-color: #313037;
    &:hover {
      background-color: #3e3d46;
    }
  }
  &--large {
    width: 180px;
    height: 56px;
  }
  &--small {
    width: 175px;
    height: 56px;
  }
}

@media screen and (max-width: 786px) {
  .button-main {
    font-size: 14px;
  }
  .button-secondary {
    font-size: 14px;
  }
}

@media screen and (max-width: 480px) {
  .button-secondary {
    font-size: 12px;
    &--small {
      width: 220px;
      height: 44px;
      svg {
        width: 16px;
        height: 16px;
      }
    }
  }
}

@media screen and (max-width: 360px) {
  .button-main {
    font-size: 12px;
  }
}
