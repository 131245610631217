@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap");

:root {
  --color-black: #000;
  --color-white: #fff;
  
}

/* @include font-face("GothamPro", "GothamPro", 400, normal); */
*,
*:before,
*:after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

*,
html,
body {
  scroll-behavior: smooth;
  
}




:focus,
:active {
  outline: none;
}

a:focus,
a:active {
  outline: none;
}

a {
  text-decoration: none;
}

body {
  box-sizing: border-box;
  position: relative;
}

aside,
nav,
footer,
header,
section {
  display: block;
}

input::-ms-clear {
  display: none;
}

button {
  cursor: pointer;
  border: none;
  background-color: transparent;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

ul li {
  list-style: none;
}

dl,
ol,
ul {
  margin: 0;
  padding: 0;
}

label {
  margin: 0;
}

p {
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: inherit;
  font-size: inherit;
  margin: 0;
  color: #ffffff;
}

input:not([type="checkbox"]):not([type="radio"]),
textarea,
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

* {
  font-family: "Inter", sans-serif;
}

body {
  background: #fbfbfb;
}

body.scroll-hide {
  overflow: hidden;
}

body .hidden {
  display: none;
}

h1 {
  font-weight: 600;
  font-size: 28px;
  line-height: 100%;
  color: #ffffff;
  text-align: center;
}

h2 {
  font-weight: 600;
  font-size: 36px;
  line-height: 140%;
  text-align: center;
  color: #ffffff;
}

h3 {
  font-weight: 600;
  font-size: 28px;
  line-height: 100%;
  color: #ffffff;
  text-align: center;
}

p {
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  color: #727272;
}

.container {
  max-width: 850px;
  margin: 0 auto;
  padding: 0 12px;

}

.container-l {
  max-width: 1240px;
  margin: 0 auto;
  @media screen and (max-width: 1000px) {
    max-width: 872px;
  } @media screen and (max-width: 786px) {
    max-width: 650px;
  } @media screen and (max-width: 480px) {

    max-width: 432px;

  }
}

.container-xl {
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 48px;
}

.page {
  position: relative;
  overflow: hidden;
  min-height: 100vh;
}

.btn {
  font-weight: 600;
  font-size: 16px;
  line-height: 120%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: -moz-fit-content;
  width: fit-content;
  padding: 12px 30px;
  border-radius: 8px;
  transition: background 0.3s ease-in-out, color 0.3s ease-in-out,
  border 0.3s ease-in-out;
  cursor: pointer;

  &__dark {
  }
}

.btn img {
  position: relative;
  z-index: 2;
}

.btn.btn--border {
  position: relative;
  color: #111111;
  border: 1px solid #727272;
}

.btn.btn--border::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background: #fbfbfb;
  border-radius: 6.5px;
  z-index: 2;
}

.btn.btn--border::after {
  position: absolute;
  content: "";
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  border-radius: 8px;
  /* background: linear-gradient(90deg, #ed4264 -14.15%, #ffde83 120.62%);
     */
  background-color: #6b45d7;
  z-index: -1;
  opacity: 0;
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
}

/* transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out; */

.btn.btn--border span {
  position: relative;
  z-index: 2;
  position: relative;
}

.btn.btn--border span::before {
  position: absolute;
  content: attr(data-hover);
  font-weight: 600;
  font-size: 16px;
  line-height: 120%;
  text-align: center;
  background: #6b45d7;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

@media (any-hover: hover) {
  .btn.btn--border:hover {
    border: 1px solid rgba(114, 114, 114, 0);
    color: transparent;
  }

  .btn.btn--border:hover span::before {
    opacity: 1;
  }

  .btn.btn--border:hover::after {
    opacity: 1;
  }
}

.btn.btn--border:active:after {
  transform: scaleY(1.03) scaleX(1.01);
}

.btn.btn--black {
  background: #111111;
  color: #fff;
  gap: 8px;
  border: 2px solid #111111;
}

.btn.btn--violet {
  width: 100%;
  height: 56px;
  background: #6b45d7;
  color: #fff;
  gap: 8px;
  font-size: 15px;
  border: 2px solid #6b45d7;
}

.btn.btn--violet:hover {
  opacity: 0.8;
}

@media (any-hover: hover) {
  .btn.btn--black:hover {
    background: #393939;
    border: 2px solid #393939;
  }
}

.btn.btn--black:active {
  border: 2px solid #393939;
}


.gallery {
  background: #111111;
  padding: 150px 0;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.gallery .box {
  max-width: 454px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 85px 55px;
}

.box {
  background: #fdfdfd;
  border-radius: 24px;
}

.box img {
  width: 72px;
  height: 72px;
}

.box h2 {
  margin-top: 24px;
}

.box p {
  margin-top: 24px;
  text-align: center;
}

.box .btn {
  padding: 24px;
  max-width: 252px;
  width: 100%;
  margin-top: 32px;
}

.box .btn img {
  width: 16px;
  height: 16px;
}

.gallery-img {
  padding: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 20px;
}

.gallery-img::before {
  position: absolute;
  content: "";
  background: linear-gradient(
                  180deg,
                  rgba(17, 17, 17, 0) 0%,
                  rgba(17, 17, 17, 0.8) 97.92%
  );
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.gallery-img img {
  height: 216px;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.header {
  // position: absolute;
  position: relative;
  width: 100%;
  // top: 0;
  z-index: 500;
  padding: 37px 0;
  border-bottom: 1px solid #19181d;

  height: 120px;
}

.header-sign {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  border: none;
}

.header-sign .nav {
  display: none;
}

.header-sign .header__right {
  display: none;
}

.header .container {
  display: flex;
  justify-content: space-between;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  max-width: 1520px;
  height: 100%;

  nav {
    margin: 0 auto;
  }
}

.header .btn-secondary {
  width: 96px;
  padding: 12px;
  height: auto;
  margin: 0 0 0 auto;
  transition: background 0.3s ease-in-out, color 0.3s ease-in-out, border 0.3s ease-in-out, transform 0.3s ease-in-out;
  &:hover {
    transform: scale(1.01);
  }
}

.logo {
  display: flex;
  align-items: center;
  gap: 16px;
  font-weight: 600;
  font-size: 18px;
  line-height: 100%;
  color: #ffffff;
}

.header-sign .logo {
  color: #fbfbfb;
}

.nav {
  // padding-right: 150px;
}

@media screen and (max-width: 1440px) {
  .header .container {

    max-width: 1270px;

  }
}

@media screen and (max-width: 1000px) {
  .nav {
    padding-right: 0;
  }
}

.nav ul {
  display: flex;
  gap: 65px;
}

.nav ul a {
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  color: #ffffff;

  /* Inside auto layout */

  transition: color 0.3s ease-in-out;
}

@media (any-hover: hover) {
  .nav ul a:hover {
    color: #c6c6c6;
  }
}

.header__right .btn-sign-done {
  padding: 0;
  border: 0;
  margin: 0 0 0 auto;
}

.btn-sign-done {
  width: 40px;
  height: 40px;
  display: block;
}

.btn-sign-done::before,
.btn-sign-done::after {
  display: none;
}

.btn-sign-done img {
  max-width: 100%;
  max-height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.header__right {
  position: relative;
  // width: fit-content;
  // display: flex;
  // justify-content: flex-end;
}

.popup-nav {
  position: absolute;
  z-index: 1000;
  background: #ffffff;
  box-shadow: 0px 35px 14px rgba(204, 204, 204, 0.01),
  0px 19px 12px rgba(204, 204, 204, 0.05),
  0px 9px 9px rgba(204, 204, 204, 0.09), 0px 2px 5px rgba(204, 204, 204, 0.1),
  0px 0px 0px rgba(204, 204, 204, 0.1);
  border-radius: 16px;
  width: 240px;
  right: 0;
  overflow: hidden;
  top: 120%;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s linear;
}

.popup-nav.active {
  opacity: 1;
  z-index: 100000;

  pointer-events: all;
}

.popup-nav h2 {
  font-size: 16px;
  z-index: 100000;

  line-height: 100%;
}

.popup-nav h2,
.popup-nav a {
  z-index: 100000;

  padding: 18px;
  display: block;
  text-align: left;
}

.popup-nav a {
  z-index: 100000;

  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: #111111;
  transition: background 0.3s linear;
}

@media (any-hover: hover) {
  .popup-nav a:hover {
    background: #f3f3f3;
  }
}

.pricing .container {
  display: flex;
  gap: 65px;
  flex-direction: column;
  max-width: 610px;
}

.pricing__left {
  max-width: 100%;
  text-align: center;
}

.pricing__left h2 {
  text-align: center;
  max-width: 300px;
  margin-left: auto;
  margin-right: auto;
}

.pricing__left p {
  text-align: center;
  margin-top: 16px;
  max-width: 300px;
  margin-left: auto;
  margin-right: auto;
}

.pricing__wrs {
  margin-left: auto;
  margin-right: auto;
}

.pricing__filter {
  margin-top: 32px;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: center;
}

.btn-filter {
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  text-align: center;
  color: #111111;
  padding: 16px;
  border: 1px solid #dddddd;
  border-radius: 1000px;
  cursor: pointer;
}

.btn-filter.active {
  color: #fff;
  background: #111111;
}

.pricing__right {
  border-radius: 12px;
  position: relative;
  background: #fbfbfb;
  padding: 56px 40px;
  width: 480px;
}

.pricing__subtext {
  max-width: 400px;
  margin: 0 auto;
}

.pricing__right::before {
  content: "";
  position: absolute;
  top: -3px;
  left: -3px;
  right: -3px;
  bottom: -3px;
  background: linear-gradient(90deg, #ed4264 -14.15%, #ffde83 120.62%);
  z-index: -1;
  border-radius: 12px;
}

.pricing__right .btn {
  margin-top: 65px;
  width: 100%;
  padding: 24px;
}

.hide {
  display: none;
}

.pricing__suma {
  /* margin-top: 44px; */
  display: flex;
  align-items: center;
  gap: 12px;
}

.pricing__suma div {
  font-weight: 600;
  font-size: 40px;
  line-height: 100%;
  color: #111111;
}

.pricing__suma div .old {
  text-decoration-line: line-through;
  color: #c6c6c6;
}

.pricing__suma p {
  font-weight: 600;
  font-size: 16px;
  line-height: 100%;
  color: #111111;
  text-align: left !important;
  margin: 0;
}

.pricing__suma-small {
  padding-bottom: 12px;
  display: flex;
  align-items: center;
  gap: 6px;
}

.pricing__suma-small div {
  font-weight: 600;
  font-size: 16px;
  line-height: 100%;
  color: #111111;
}

.pricing__suma-small div .old {
  text-decoration-line: line-through;
  color: #c6c6c6;
  margin-right: 6px;
}

.list-check {
  margin-top: 48px;
}

.list-check li {
  font-weight: 600;
  font-size: 16px;
  line-height: 100%;
  color: #111111;
  margin-left: 32px;
  position: relative;
  margin-bottom: 40px;
}

.list-check li:last-child {
  margin-bottom: 0;
}

.list-check li::before {
  content: "";
  position: absolute;
  background-image: url("../img/list.svg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 16px;
  height: 16px;
  left: -32px;
  top: 0;
}

.slider {
  position: relative;
}

.slider.load::before {
  content: "";
  position: absolute;
  left: 0;
  top: -10px;
  width: 100%;
  height: 100%;
  background: #fbfbfb;
  z-index: 10;
}

.slider.load::after {
  content: "";
  width: 20px;
  height: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -10px;
  margin-left: -10px;
  background: url(../img/load.png) center/contain no-repeat;
  z-index: 15;
  animation: 3s ease-in 1s infinite reverse both running slidein;
  animation-name: example;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

@keyframes example {
  0% {
    transform: rotate(360deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

.swiper {
  padding-bottom: 2.08333vw !important;
}

.swiper-wrapper {
  transition-timing-function: linear !important;
}

.swiper-slide {
  display: flex !important;
  flex-direction: column;
  gap: 20px;
  margin-right: 20px;
}

.child:nth-child(even) .swiper-slide {
  padding: 20px 0 0 0;
}

.swiper-slide img {
  width: 13.19444vw;
  height: 13.19444vw;
  border-radius: 0.83333vw;
}

.swiper-slide:nth-child(even) img {
  transform: translateY(2.08333vw) !important;
}

.video__top {
  max-width: 470px;
  margin: 0 auto;
}

.video__loader {
  background: #110e14;
  width: 100%;
  height: 100vh;
  padding: 72px 0 0 0;

  .loader__ico {
    margin: 0 auto;
  }
}

.video__top a {
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  text-align: center;
  background: linear-gradient(90deg, #ed4264 -14.15%, #ffde83 162.4%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  margin: 32px auto 0 auto;
  display: block;
  position: relative;
  width: -moz-fit-content;
  width: fit-content;
  transition: filter 0.3s ease-in-out;
  /* &::before {
        position: absolute;
        content: "";
        width: 100%;
        height: 1px;
        background: linear-gradient(
            90deg,
            #ed4264 -14.15%,
            #ffde83 120.62%
        );
        left: 0;
        bottom: -2px;
    } */
}

@media (any-hover: hover) {
  .video__top a:hover {
    filter: brightness(0.5);
  }
}

.video__top p {
  margin: 16px auto 0 auto;
  text-align: center;
}

.video__wrapper {
  max-width: 1000px;
  margin: 0 auto;
}

@media screen and (max-width: 1090px) {
  .video__wrapper {
    max-width: 800px;
    margin: 0 auto;
  }
}

@media screen and (max-width: 1000px) {
  .video__wrapper {
    max-width: 100%;
    padding: 0 12px;
    margin: 0 auto;
  }
}

.video__wrapper video,
.video__wrapper iframe {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  border: none;
  border-radius: 20px;
}

.sign-block {
  max-width: 516px;
  margin: 48px 0 0 0;
  height: fit-content;
  /* margin: 10vh auto 0; */
  flex: 1;

  gap: 40px;

  /* margin-top: -121px; */

  display: flex;
  display: flex;
  align-items: center;
  justify-content: center;
  /* min-height: 860px; */
}


.form {
  width: 100%;
  position: relative;
}

.error-show {
  opacity: 1;
  pointer-events: all;
  position: static;
  margin: 0 auto 20px auto;
  display: block;
  width: fit-content;
}

.form__input {
  width: 100%;
  height: 64px;
  margin-bottom: 24px;
  position: relative;
}

.form__input input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: #2b2b2b;
  padding: 16px;
  border: 1px solid #c6c6c6;
  border-radius: 8px;
  width: 100%;
  height: 100%;
  background: #fbfbfb;
  position: relative;
}

.form__input input:focus {
  border: 2px solid #6b45d7;
}

.form__input input::-moz-placeholder {
  color: #727272;
}

.form__input input::placeholder {
  color: #727272;
}

.form__input input.error {
  border: 2px solid #de1212;
}

.form__input input.error + .error-show {
  opacity: 1;
  pointer-events: all;
}

.error-show {

}

.form_or {
  position: relative;
  margin: 40px 0;
  display: flex;
  align-items: center;
  height: 18px;
}

.form_or > span {
  display: block;
  width: fit-content;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  background: #fbfbfb;
  padding: 0 8px;

  font-style: normal;
  font-weight: 500;
  font-size: 14px;

  color: #b0b0b0;
}

.form_line {
  width: 100%;
  height: 1px;
  background-color: #dddddd;
}

.privacy {
  padding: 80px 0 164px 0;
}

.privacy .container {
  max-width: 554px;
  display: flex;
  flex-direction: column;
  gap: 48px;
}

.privacy .container h2 {
  text-align: left !important;
  margin-bottom: 6px;
}

.privacy .container h2 {
  font-weight: 600;
  font-size: 18px;

  line-height: 150%;
  display: flex;
  align-items: center;
  color: #ffffff;
}

@media screen and (max-width: 1000px) {
  .privacy {
    padding: 80px 0 112px 0;
  }

  .privacy .container h2 {
    font-size: 16px;
  }
}

.privacy .container h4 {
  font-weight: 600;
  font-size: 14px;
  line-height: 100%;
  display: flex;
  align-items: center;
  color: #ffffff;
  margin-bottom: 24px;
}

.privacy .container p {
  word-wrap: break-word;
  // margin-bottom: 40px;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
}

.privacy .container p a {
  word-wrap: break-word;
  margin-bottom: 24px;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #727272;
}

.privacy .container ul {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.privacy .container section {
  display: flex;
  flex-direction: column;
  gap: 24px;

  &:first-child {
    gap: 16px;
  }
}

.privacy .container li {
  list-style: disc;
  list-style-position: inside;
  word-wrap: break-word;

  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: #727272;
}

@media screen and (max-width: 786px) {
  .privacy {
    padding: 80px 0 104px 0;
  }
  .privacy .container h1 {
    font-size: 20px;
  }
  .privacy .container h2 {
    font-size: 16px;
  }
  .privacy .container p {
    font-size: 12px;
  }
  .privacy .container p a {
    font-size: 12px;
  }
  .privacy .container {
    gap: 40px;
  }
  .privacy .container section {
    gap: 20px;

    &:first-child {
      gap: 12px;
    }
  }
}

@media screen and (max-width: 480px) {
  .privacy .container h1 {
    font-size: 18px;
  }
  .privacy .container h2 {
    font-size: 14px;
  }
  .privacy .container p {
    font-size: 12px;
  }
}

@media screen and (max-width: 480px) {
  .privacy {
    padding: 80px 0 96px 0;
  }
}

@media screen and (max-width: 1000px) {
  .user-title {
    font-size: 24px;
  }
}

@media screen and (max-width: 768px) {
  .user-bottom {
    margin-top: 48px;
  }
}

@media screen and (max-width: 480px) {
  .user-bottom {
    margin-top: 40px;
  }
}

.subscription {
  // margin-top: 24px;
  background: #19181d;
  border-radius: 20px;
  padding: 32px 28px;
}

.subscription-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.subscription-top .btn {
  padding: 11px 21px;
}

.subscription-top__logo {
  display: flex;
  align-items: center;
  gap: 12px;
  font-weight: 600;
  font-size: 16px;
  line-height: 100%;
  color: #c6c6c6;
}

.subscription-top__logo.done {
  color: #1bc440;
}

.subscription-top__logo.done::after {
  content: "";
  background-image: url("../img/check-arrow.svg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 16px;
  height: 16px;
}

.subscription-top__logo img {
  width: 40px;
  height: 40px;
}

.subscription__text {
  margin-top: 40px;
}

.subscription__text h3 {
  margin-bottom: 8px;
}

.subscription__btn {
  padding: 17px;
  margin-top: 32px;
  width: 100%;
}


.promo {

  .container {
    max-width: 1270px !important;
  }
}

@media (max-width: 1000px) {
  .header .container {
    grid-template-columns: 1fr 1fr;
    padding: 0 80px;
  }
  .header {
    .btn-secondary {
      width: 109px;
      height: auto;
    }
  }

  .header .nav {
    display: none;
  }

  .pricing .container {
    flex-direction: column;
    align-items: center;
    gap: 56px;
  }

  .pricing__filter {
    justify-content: center;
  }

  .pricing__left h2,
  .pricing__left p {
    text-align: center !important;
  }

  .pt {
    // padding: 52px 0px;
  }

  .slider {
    // padding-bottom: 52px;
  }

  .pricing .container {
    gap: 40px;
  }

  .pricing__filter {
    margin-top: 30px;
  }

  


}

@media (max-width: 768px) {
  .swiper-slide {
    display: flex !important;
    flex-direction: column;
    gap: 12px;
    margin-right: 12px;
  }

  .child:nth-child(even) .swiper-slide {
    padding: 12px 0 0 0;
  }
  h1 {
    font-size: 24px;
    line-height: 140%;
  }

  h2 {
    font-size: 18px;
    line-height: 140%;
  }

  .video__top {
    max-width: 353px;
  }

  .header {
    padding: 28px 0;
  }

  .header .container {
    padding: 0 50px;
  }

  p {
    font-size: 14px;
    line-height: 140%;
  }

  .promo__btns .btn {
    padding: 20px;
  }

  .promo__btns {
    gap: 24px;
  }

  .promo {
    // padding: 56px 0;
  }

  .slider {
    // padding-bottom: 44px;
  }

  .pt {
    // padding: 44px 0px;
  }

  .video_first {
    // padding-bottom: 36px;
  }

  .video__wrapper {
    max-width: 1023px;
    margin: 0 auto;
  }

  .pricing__right {
    padding: 40px;
  }

  .pricing__right .btn {
    padding: 20px;
    margin-top: 40px;
  }

  .list-check li {
    margin-bottom: 32px;
  }

  .list-check {
    margin-top: 40px;
  }

  /* .pricing__suma {
      margin-top: 32px;
    } */

  .pricing .container {
    gap: 40px;
  }

  .pricing__filter {
    margin-top: 24px;
  }

  .gallery .box {
    padding: 50px 35px;
    max-width: 325px;
  }

  .box p {
    font-size: 12px;
    line-height: 150%;
  }

  .box h2 {
    font-size: 16px;
    line-height: 100%;
  }

  .box .btn {
    padding: 20px;
  }

  .gallery {
    padding: 50px 0;
  }

  .gallery-img img {
    height: 121px;
  }

  .gallery-img {
    gap: 10px;
    padding: 10px;
  }


  .user-center .btn {
    padding: 20px;
  }

  .header {
    height: 104px;
  }

  .slider.load::after {
    width: 16px;
    height: 16px;
    margin-top: -8px;
    margin-left: -8px;
  }



  .pricing__right {
    width: 442px;
  }
}

@media (max-width: 480px) {
  .header {
    padding: 20px 0;
  }

  .header {
    .btn-secondary {
      width: 88px;
      font-size: 12px;
      height: auto;
      padding: 10px 19.5px;
      line-height: 100%;
    }
  }

  .header .container {
    padding: 0 20px;
  }

  h1 {
    font-size: 18px;
    line-height: 140%;
  }

  .btn {
    font-size: 14px;
    line-height: 100%;
  }

  .promo__btns .btn {
    padding: 17px;
  }

  .promo__btns {
    gap: 16px;
    margin-top: 24px;
  }

  .pt {
    // padding: 40px 0px;
  }

  .slider {
    // padding-bottom: 40px;
  }

  .pricing__right {
    width: 96.8%;
    margin: 0 auto;
  }

  .pricing__wrs {
    width: 100%;
  }

  .logo {
    font-size: 16px;
    line-height: 100%;
  }

  .pricing__suma div {
    font-size: 32px;
    line-height: 100%;
  }

  .pricing__suma p {
    font-size: 14px;
    line-height: 140%;
  }




  .form__input input {
    font-size: 12px;
    line-height: 140%;
  }

  .form__input {
    height: 48px;
  }

  .sign-block .btn {
    padding: 17px;
  }

  .sign-block {
    padding: 0 15px;
  }

  .form__input {
    margin-bottom: 16px;
  }

  .user-center .btn {
    padding: 17px;
  }

  .subscription-top__logo {
    font-size: 14px;
    line-height: 100%;
  }

  .subscription-top__logo img {
    width: 36px;
    height: 36px;
  }

  .subscription__btn {
    padding: 17px !important;
  }

  .nav ul {
    gap: 24px;
  }

  .nav ul a {
    font-size: 14px;
    line-height: 140%;
  }

  .header {
    height: 80px;
  }


  .btn-filter {
    font-size: 12px;
    padding: 12px 8px;
  }

  .box .btn {
    padding: 14px;
  }
}

@media (max-width: 380px) {
  .header {
    .btn-secondary {
      width: 86px;
      font-size: 12px;
    }
  }
  .promo__btns .btn {
    max-width: 100%;
  }

  .pricing__right {
    width: 100%;
    padding: 32px 24px;
  }

  .pricing__suma div {
    font-size: 24px;
    line-height: 140%;
  }

  .pricing__suma p {
    font-size: 12px;
    line-height: 140%;
  }

  .list-check li {
    font-size: 12px;
    line-height: 100%;
    margin-bottom: 25px;
  }

  .list-check li::before {
    top: -2px;
  }


  .nav ul {
    gap: 24px;
  }

  .nav ul a {
    font-size: 14px;
    line-height: 140%;
  }

  .btn {
    padding: 10px 20px;
  }

  .logo {
    font-size: 14px;
    line-height: 100%;
  }

  .sign-block h2 {
    font-size: 18px;
    line-height: 140%;
  }

  .user h3 {
    font-size: 14px;
    line-height: 100%;
  }

  .header {
    height: 72px;
    padding: 13px 0;
  }

  .promo {
    // padding: 100px 0 48px 0;
  }

  .video_first {
    // padding-bottom: 8px;
  }

  .pricing__filter {
    gap: 8px;
  }
}

.main-sign {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url("../img/bg.webp") center / cover no-repeat;
}

@media screen and (max-width: 480px) {
  .main-sign {
    align-items: inherit;
    padding-top: 60px;
  }
}

@media screen and (max-width: 360px) {
  .main-sign {
    padding-top: 40px;
  }
}

.sign-tabs__name {
  cursor: pointer;
  font-style: normal;
  font-weight: 600 !important;
  font-size: 24px;
  line-height: 100%;
  /* identical to box height, or 24px */

  /* Dark 1000 */

  color: #b0b0b0;
}

.sign-tabs__name--active {
  color: #111111;
  border-bottom: 2px solid black;
  padding-bottom: 20px;
}

.sign__tabs {
  display: flex;
  gap: 40px;
}

.none {
  display: none;
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000000;
  position: fixed;
  /* top: 120px; */
  left: 0;
  top: 0;
  width: 100%;
  /* height: calc(100% - 120px);
     */
  height: 100%;
  // background: rgba(251, 251, 251, 1);
  background: #000000;
}

.loader__ico {
  animation-name: loader;

  animation-timing-function: linear;
  animation-duration: 500ms;
  animation-iteration-count: infinite;
  width: 24px;
  height: 24px;
  overflow: visible;
}

.loader-mini {
  margin: 48px 0 0 0;
}

.loader-mini div {
  margin: 0 auto;
}

.loader__ico img {
  width: 100%;
}

@keyframes loader {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

.btn-sign-done > img {
  width: 44px;
  height: 44px;
}

.user-top > img {
  width: 44px;
  height: 44px;
}

.plan {
  color: #ffffff;
}

.user-center-text {
  font-size: 12px;
  line-height: 100%;
  /* identical to box height, or 12px */

  /* Dark 300 */

  color: #b0b0b0;
}

.subscription__list {
  display: flex;
  flex-direction: column;
  gap: 24px;

  margin-top: 32px;
}

.subscription__item {
  display: flex;
  padding-bottom: 12px;
  gap: 9.75px;
  font-weight: 600;
  font-size: 12px;
  line-height: 140%;
  color: #f3f3f3;
  border-bottom: 1px solid #252323;
}

.subscription__item::before {
  content: "";

  display: block;
  width: 10.5px;

  background: url("../img/start.svg") center / contain no-repeat;
}

@media screen and (max-width: 480px) {
  .subscription__item {
    font-size: 12px;
  }
}

.video__tabs {
  width: fit-content;
  margin: 56px auto 0 auto;
  display: flex;
  gap: 34px;
}

.video__tab {
  flex-shrink: 1;
  width: fit-content;
  cursor: pointer;
  display: flex;
  align-items: center;
  background: none;
  border: 1px solid #19181d;
  color: #ffffff;
  border-radius: 100px;
  padding: 16px 18px;
  gap: 10px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 100%;

  text-align: center;
  color: #ffffff;

  transition: linear 0.2s;

  &:hover {
    border: 1px solid #727272;
  }
}

.video__tab-ico path {
  transition: linear 0.2s;
  stroke: #ffffff;
}

.video__tab .video__tab:hover > .video__tab-ico path {
  stroke: #fbfbfb;
}

.video__tab-active {
  position: relative;
  color: #fbfbfb;
  overflow: hidden;
  background: #212027;
  border: 1px solid #212027;

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 10%;
    content: "";
    width: 100%;
    height: 100%;
    background: red;
    background: url("../img/gif2.gif") center / 150% repeat;
  }

  &:hover {
    border: 1px solid #212027;
    // color: #fbfbfb;
    // border: 1px solid #727272;
  }
}

.video__tab-active > .video__tab-ico path {
  stroke: #fbfbfb;
}

.video__text {
  display: flex;
  width: fit-content;
  margin: 48px auto;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  /* or 22px */

  position: relative;
  text-align: center;

  /* Dark 600 */

  color: #b0b0b0;

  h3 {
    max-width: 370px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    /* or 22px */

    position: relative;
    text-align: center;

    /* Dark 600 */

    color: #b0b0b0;
  }

  &--long {
    max-width: 457px;
  }
}

.video__text::before {
  flex-shrink: 0;
  content: "";
  width: 6px;
  height: 6px;
  border-radius: 100px;
  background-color: #727272;
  display: block;
  position: relative;
  left: 0px;
  top: 8px;
}

@media screen and (max-width: 1000px) {
  .video__tabs {
    gap: 24px;
  }
  .video__text {
    margin: 40px auto;
  }
}

@media screen and (max-width: 786px) {
  .video__text {
    max-width: 390px;
    font-size: 14px;

    h3 {
      font-size: 14px;
    }
  }
}

@media screen and (max-width: 580px) {
  .video__tabs {
    margin: 40px auto 0;
    gap: 24px;
    flex-direction: column;
  }
  .video__tab {
    width: 190px;
    justify-content: center;
  }
  .video__text {
    max-width: 390px;
    margin: 32px auto;
  }
}

@media screen and (max-width: 360px) {
  h2 {
    font-size: 18px;
  }
  .video__tab {
    font-size: 12px;
  }
  .video__text::before {
    width: 4px;
    height: 4px;
  }
  .video__text {
    font-size: 12px;
    max-width: 280px;

    h3 {
      max-width: 262px;
      font-size: 12px;
      margin: 0 auto;
    }
  }
}

.section-start {
  opacity: 0;
}

.animation {
  animation-name: primaryAnimation;
  animation-duration: 1.5s;
  animation-fill-mode: forwards;
}

@keyframes primaryAnimation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.btn-connect {
  background: #4b4b4b;
  /* Dark 800 */
  color: #ffffff;

  border: 1px solid #4b4b4b;
}

.logo__img {
  width: 40px;
  height: 40px;
  overflow: hidden;

  img {
    width: 100%;
  }
}

@media screen and (max-width: 480px) {
  .logo {
    gap: 12px;
    font-size: 12px;
  }
  .logo__img {
    width: 32px;
    height: 32px;
  }
  .btn-sign-done {
    width: 32px;
    height: 32px;
  }
  .btn-sign-done > img {
    width: 32px;
    height: 32px;
  }
}

@media screen and (max-width: 360px) {
  .logo__img {
    width: 28px;
    height: 28px;
  }
  .btn-sign-done {
    width: 28px;
    height: 28px;
  }
  .btn-sign-done > img {
    width: 28px;
    height: 28px;
  }
}


.header-animation {
  animation-name: headerAnim;
  animation-duration: 1s;
  animation-fill-mode: forwards;

}

@keyframes headerAnim {
  from {
    transform: translateY(-20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

body::-webkit-scrollbar {
  width: 3px;            
}

body::-webkit-scrollbar-track {
  background: #110e14;       
}

body::-webkit-scrollbar-thumb {
  background-color: #959597;    
  border-radius: 20px;       
  &:active {
    background-color: red;    /* color of the scroll thumb */
  }

}


@import "_typography";
@import "SignIn/_signIn";
@import "components/_input";
@import "components/_forgot";
@import "landing/_problem-solving";