.user {
  max-width: 400px;
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  align-items: center;
  a, button {
    transition: background 0.3s ease-in-out, color 0.3s ease-in-out, border 0.3s ease-in-out, transform 0.3s ease-in-out;
    &:hover {
      transform: scale(1.01);
    }
  }
  &__plan-name {

    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 24px */
    color: #FFFFFF;
  }
  .button-secondary--small {
    margin-top: 32px;
  }
  &-cancel {
    transition: linear 0.3s;
    &:hover {
      color: #fff;
    }
  }
  &-center {
    margin-top: 48px;
    width: 100%;
    button {
      width: 100%;
    }

    h3 {
      text-align: left;
      padding-bottom: 20px;
    }
  }
  &__plan-days-left {
    background: red;
    padding: 6px 10px;
    border-radius: 6px;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; /* 10px */
    background: #FAEFE5;
    color: #111111;

  }

  &-text {
    padding-top: 12px;
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
  }
  &-buttons {
    display: flex;
    flex-direction: column;
    gap: 20px;
    a,
    button {
      width: 100%;

    }

    & ~ span {
      width: fit-content;
      display: block;
      margin: 16px 0 0 0;
    }
  }
}

.user-top {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.user-center .btn {
  padding: 24px;
  width: 100%;
}

.user-bottom {
  margin-top: 64px;
  width: 100%;
}

.user-title {
  margin: 0;
  font-weight: 600;
  font-size: 28px;
  line-height: 100%;

  /* Dark 1000 */

  color: #ffffff;
}

.user-email {
  padding-top: 12px;
  color: #727272;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.user h3 {
  font-weight: 600;
  font-size: 16px;
  line-height: 100%;
  color: #ffffff;
}

.user-top h2 {
  margin-top: 24px;
}

.user-top p {
  margin-top: 8px;
}

.user-wrapper {
  min-height: 100vh;
  margin: 72px 0 120px 0;
}

@media screen and (max-width: 1000px) {
  .user {
    &-title {
      font-size: 24px;
    }
  }

  .user-wrapper {
    margin: 64px 0;
  }
}

@media screen and (max-width: 786px) {
  .user {
    &-title {
      font-size: 20px;
    }
    &-text {
      font-size: 14px;
    }

    &-buttons {
      gap: 16px;
    }
  }

}

@media screen and (max-width: 480px) {
  .user {
    max-width: 100%;
    padding: 0 20px;
    margin: 36px auto;
    h3 {
      font-size: 14px;
    }
    &-title {
      font-size: 18px;
    }
    &-text {
      font-size: 12px;
      padding-top: 8px;
    }
    &-buttons {
      gap: 12px;
    }
    &-center {
      margin-top: 32px;
      .button-main,
      .button-secondary {
        font-size: 12px;
        margin: 0 auto;

        height: 40px;
      }
    }
  }

  .user-wrapper {
    margin: 36px 0 128px 0;
  }
}

@media screen and (max-width: 360px) {
}
