// ============= Generate Animations =============
.bento-ga {

  &__block {
    padding: 40px 32px 40px 32px;
    //background: url('../../../../img/bendo/block_bg.png') 0 0 / 400px 340px repeat;
    background: #120F17;
    border: 1px solid #2E2B35;
    border-radius: 20px;
  }


  &__images {
    display: flex;
    gap: 16px;
  }


  &__image-block {

    max-width: 266px;
    aspect-ratio: 257/200;
    flex-shrink: 1;
    border-radius: 16px;
    overflow: hidden;
    border: 1px solid #2E2B35;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.31);
    background-color: #2E2B35;

    img {
      width: 100%;

    }
  }


  @media screen and (max-width: 1000px) {


    &__image-block {
      width: 100%;
      max-width: 174px; 
      // flex-shrink: 0;
      aspect-ratio:  174/ 215;

      img {
        position: relative;
        width: 100%;
        
        object-fit: cover;
        height: 100%;
        // right: 20px;

      }

      &:nth-child(1) {
        img {
          // right: 14px;
        }
      }

    }


  }

  @media screen and (max-width: 786px) {

    &__block {
      padding: 32px 24px 40px;
      .bento__title-h4 {
        padding-bottom: 28px;
      }
    }


    &__image-block {
      max-width: 264px;
      aspect-ratio: 579/500;

      &:first-child {
        display: none;
      }

      img {
        width: 100%;
        height: auto;
        right: 0;
        bottom: 15px;
      }
    }

  }

  @media screen and (max-width: 670px){
    &__block {
      padding: 32px 24px 0 24px;
    }
    &__image-block {
      aspect-ratio: 579/561;
      img {
        bottom: 0;
      }
    }
  }

  @media screen and (max-width: 560px) {
    &__block {
      width: 100%;
      padding: 32px 24px 40px;
      
    }


    &__image-block {
      max-width: 384px;
      aspect-ratio: 1561/1000;


      img {

        bottom: 30px;
      }
    }
  }


  @media screen and (max-width: 360px) {
    &__block {
      padding: 28px 20px 24px;
    }


    &__image-block {
      max-width: 272px;
      aspect-ratio: 636/500;


      img {

        bottom: 30px;
      }
    }
  }

}