@import "_GenerateAnimations";
@import "_GenerateCopy";
@import "_GenerateUserPersonas";
@import "_GenerateMindMaps";
@import "_GenerateSiteMaps";
@import "_GenerateUJMs";
@import "_GenerateImages";
@import "_GenerateImageVariants";

.bento {

  padding: 0 24px;
  &__title {

    p {
      margin: 0 auto;
      padding: 20px 0 72px;
      max-width: 420px;
      color: var(--web-colors-text-colors-aluminum-silver, #B0B0B0);
      text-align: center;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 24px */
    }
  }
  &__title-h4 {
    color: #FFF;
    font-size: 18px;
    font-style: normal;
    
    font-weight: 600;
    line-height: 1;
    padding-bottom: 40px;
    text-align: left;
  }

  &__container {
    max-width: 1240px;
    margin: 0 auto;
  }

  &__content {
    display: grid;
    grid-auto-flow: row;
    gap: 16px;
    grid-template-columns: repeat(2, 1fr);
  }
  &__subgrid {
    margin: 16px 0;
    display: grid;
    gap: 16px;
    grid-template-columns: repeat(4, 0.25fr);
  }


  @media screen and (max-width: 1000px) {
    &__title {

      p {
        font-size: 16px;
        padding: 16px 0 56px;
        max-width: 420px;
      }
    }
    &__title-h4 {
      padding-bottom: 36px;
    }
    &__subgrid {
      grid-template-columns: 1fr 1fr;
    }
    &__container {
      max-width: 872px;
    }
  }

  @media screen and (max-width: 786px) {
    &__title {

      p {
        font-size: 12px;
        max-width: 320px;
      }
    }
    &__title-h4 {
      font-size: 16px;
      padding-bottom: 36px;
      line-height: 150%;
    }

    &__container {
      max-width: 640px;
    }
  }


  @media screen and (max-width: 560px) {
    &__title {

      p {
        padding: 16px 0 44px;
        font-size: 12px;
      }
    }
    &__title-h4 {
      font-size: 14px;
      padding-bottom: 32px;
    }
    &__container {
      max-width: 432px;
    }
    &__content {
      grid-template-columns: 1fr;
    }
    &__subgrid {
      grid-template-columns: 1fr;
      //grid-template-rows: repeat(4, 1fr);
      grid-template-rows: 1fr 1fr auto 1fr;

    }
  }


  @media screen and (max-width: 360px) {
    &__title {

      p {
        padding: 16px 0 44px;
      }
    }
    &__title-h4 {
      padding-bottom: 32px;
    }
    &__container {
      max-width: 312px;
    }
    &__subgrid {
      grid-template-rows: auto 1fr 1fr auto;
    }
  }
}





